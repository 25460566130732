
@import "~@/assets/scss/abstracts/_variables.scss";
.yit-small-icon {
    display: flex;
    padding: 0;
    line-height: 1;
    align-items: center;
    justify-content: center;
    width: 1.7rem;
    height: 1.7rem;
    img {
        width: 100%;
        height: auto;
        margin-top: -1rem;
    }
}
