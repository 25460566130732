
@import "@/assets/scss/abstracts/_variables.scss";
.profile-menu {
    &__dropdown {
        position: absolute;
        width: 100%;
        right: 1rem;
        max-width: 290px;
        width: 100%;
        z-index: 10;
        color: #fff;
        font-size: .9rem;

        &.is-visible {
            box-shadow: 0px;
        }
        &.is-visible {
            display: block;
        }

        &:before {
            content: "";
            top: -8px;
            left: auto;
            right: 1rem;
            z-index: 0;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 1rem 1rem 1rem;
            border-color: transparent transparent $greyBlueMidDark transparent;
            transition: all 0.2s ease-in-out 0.5s;
        }
    }
    &__container {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid rgba(255,255,255,.15);
        &:last-child {
            border-bottom: 0;
        }
    }
    .current-user {
        &__label {
            font-size: .85rem;
            opacity: .7;
        }
        &__name {
            margin-top: .3rem;
            font-family: $fontPrimaryBold;
        }
    }
    .user-menu {
        padding: 0;
        margin: 0;
        list-style: none;
        li a {
            display: block;
            padding: .4rem 0;
            i {
                font-size: 1.1rem;
                position: relative;
                top: .1rem;
                margin-right: .5rem;
            }
        }
    }
}

