
@import "~@/assets/scss/abstracts/variables";
.switch {
  position: relative;
  display: inline-block;
  width: 3.15rem;
  height: 1.62rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: $brandColorPrimary;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $brandColorPrimary;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
}

.boolean-field {
  .switch__label {
    display: block;
    margin-bottom: 0.5rem;
  }
  @media screen and (min-width: $breakpoint-sm) {
    &--inline {
      padding: .4rem 0;
      .switch__label {
        display: inline;
        position: relative;
        margin-right: 1rem;
        top: .15rem;
      }
    }
  }

  &--size-sm {
    .switch {
        
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 1rem;
    width: 1rem;
    left: 0.3rem;
    bottom: 0.3rem;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  /* Rounded sliders */
  &--round {
    border-radius: 34px;
  }

  &--round:before {
    border-radius: 50%;
  }
}
