
@import "~@/assets/scss/abstracts/variables";
.button--loading {
  position: relative;
  margin: 0 auto;
  &.button--primary,
  &[type="submit"] {
    &:focus,
    &:hover,
    &:active {
      background: $brandColorPrimary !important;
      border-color: $brandColorPrimary !important;
      color: #fff !important;
    }
  }
  .loader-icon {
    position: absolute;
    top: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0.4rem;
  }
  span {
    /*padding-left: 2rem;*/
    visibility: hidden;
    opacity: 0;
  }
}
