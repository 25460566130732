/**
 * Admin panels search form
 */
.search-and-filter {
  background: #fff;
  margin-bottom: 2rem;
  font-size: 0.9rem;

  h2,
  h3 {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-family: $fontPrimaryBold;
    cursor: pointer;

    > i {
      margin-right: 0.3rem;
    }

    button,
    .button--link {
      margin-left: auto;
      text-decoration: none;
      border-bottom: 0;
      i {
        font-size: 2.2rem;
        margin-right: 0;
      }
    }
  }
  .datepicker-spacer {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: -0.25rem;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: 0.6rem 1rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding: 0.6rem 1.8rem;
  }
}
