/**
 * Buttons
 */

$buttonBorderRadius: 2px;

input[type="button"],
button {
  border: 0;
  background: transparent;
}
.button,
input[type="submit"],
button[type="submit"] {
  font-family: $fontPrimaryMedium;
  color: $baseFontColor;
  border: 1px solid $brandColorPrimary;
  padding: 0.6rem 1.3rem;
  font-size: 0.8em;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;

  &.button--link,
  &.button--archive {
    background: transparent;
    border-color: transparent;
    color: inherit;
    border-bottom: 1px solid $greyBlueDark;
    padding: 0 0 0.1em 0;
  }
  &.button--archive {
    color: $blueMedium;
    border-bottom: 1px solid $blueMedium;
  }
  &.button--cta {
    display: block;
    width: 100%;
    max-width: 450px;
    margin: 1rem auto;
  }

  .far,
  .fas,
  .fal {
    margin-right: 0.5rem;
  }
}
.button {
  &--primary, &--secondary {
    text-transform: uppercase;
    border-radius: $buttonBorderRadius;
  }
  &--secondary {
    background: #fff;
    border: solid 1px $brandColorPrimary;
    &:hover {
      background-color: $backgroundColorGreyMid;
    }
    &:focus {
      background-color: $chateauGrey;
    }
  }
}
a.button.button--secondary {
  border-bottom: solid 1px $brandColorPrimary;
  &:focus,  
  &:hover {
    border-bottom: solid 1px $brandColorPrimary;
  }
}

input[type="submit"],
button[type="submit"],
.button.button--primary {
  background: $brandColorPrimary;
  color: #fff;
  border: 1px solid $brandColorPrimary;

  &:active,
  &:hover {
    background: #000;
    border-color: #000;
  }
  &:focus {
    background: $brandColorPrimary;
    color: $greyBlueLight;
  }
  .loader-icon {
    margin-top: .5rem;
  }
}

.button.button--white {
  background: #fff !important;
  color: $greyBlueMidDark  !important;
  &:disabled,
  &:active,
  &:focus,
  &:hover {
    background: #fff !important;
    color: $greyBlueMidDark !important;
  }
  &.button--disabled,
  &.button--primary {
    background: #fff !important;
    color: $greyBlueMidDark !important;
    &:disabled,
    &:active,
    &:focus,
    &:hover {
      background: #fff !important;
      color: $greyBlueMidDark !important;
    }
  }
  .loader-icon {
    margin-top: .75rem;
  }
}

.button.button--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.button--block {
  display: block;
  width: 100%;
}

.button--small {
  font-size: 0.7rem;
}

.button--arrow {
  position: relative;
  &:after {
    content: "\F054";
    float: right;
    font-family: $fontIcon;
    transition: all 0.2s ease-in-out;
    font-size: 1.1rem;
    margin-top: -0.1rem;
  }
  &:hover,
  &:focus {
    &:after {
      transform: translateX(0.4rem);
    }
  }
}
.button--unstyled {
  border: 0px;
  padding: 0px;
  border: 0px;
  &:hover,
  &:focus {
    border-width: 0px;
  }

  .loader-icon {
    margin: 0;
    width: 1rem;
    height: 1rem;
    .spinner div {
      top: .25rem;
      width: .7em;
      height: .7em;
      border-width: 0.15em;
    }
  }
}

input:disabled,
button:disabled,
button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  &:hover,
  &:focus {
    opacity: 0.5;
    background-color: inherit;
  }

  &.button--primary,
  &[type="submit"] {
    &:hover,
    &:focus {
      background: $brandColorPrimary !important;
      border-color: $brandColorPrimary !important;
      color: #fff !important;
    }
  }
}
input[type="submit"]:disabled,
.button.button--primary:disabled,
.button--primary:disabled {
  background: $greyBlueMidDark !important;
  border-color: $greyBlueMidDark !important;
  color: #fff !important;
}

.button--load-more {
  display: inline-block;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 1rem;
  padding: 0.4rem 0.6rem;
  font-size: 0.85rem;
  transition: opacity 0.2s ease-in;

  i {
    margin-right: 0.4rem;
  }
  &--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    opacity: 0.7;
  }
}

.button--link.button--loading,
.button--archive.button--loading {
  border-bottom: 0 !important;
}

/* Rounded button */
.rounded-button {
  background: $greyBlueDark;
  color: #fff;
  display: block;
  text-align: center;
  bottom: 1rem;
  right: 1rem;
  font-size: 1.3rem;
  height: 2.3em;
  width: 2.3em;
  padding: 0.4em;
  border-radius: 50%;
  box-shadow: 1px 3px 5px -2px rgba(0, 0, 0, 0.28);
  transform: translateY(0);
  opacity: 1;
  transition: all 0.15s ease-in;
  i {
    font-size: 1em;
  }

  &.hide {
    transform: translateY(100px);
    opacity: 0;
  }

  &--fixed {
    position: fixed;
  }

  &--right-down {
    bottom: 1rem;
    right: 1rem;
  }

  &--left-down {
    bottom: 1rem;
    left: 1rem;
  }
}