
@import "~@/assets/scss/abstracts/_variables.scss";

.test-environment-nag {
  display: none;
  @media screen and (min-width: $breakpoint-lg) {
    display: block;
    position: fixed;
    top: 0rem;
    right: 0rem;
    font-size: 0.65rem;
    background: $red;
    color: #fff;
    padding: 0.4rem 0.6rem;
    z-index: 10;
    top: 62px;
  }

  &.page-has-old-plus-link {
    top: 100px;
  }
}
.back-to-old-plus {
  position: fixed;
  display: flex;
  align-items: center;
  right: 0;
  top: 60px;
  z-index: 10;
  padding: 0.5rem;
  font-size: 0.85rem;
  i {
    margin-left: 0.2rem;
  }
  .loader-icon {
    position: relative;
    margin: 0;
    font-size: 0.7rem;
    height: 1rem;
    top: 0.3rem;
  }
}
.maintennance-break {
  padding: 10rem 2rem 0 2rem;
}
