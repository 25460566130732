
.report-violation {
  &__instructions {
    margin: 1rem 0 2rem 0;
    padding: 0px 20px 0 0;
    text-align: left;
  }
}
.report-message {
  &__report-button {
    cursor: pointer;
  }
  &__text {
    font-size: .85rem;
    margin-left: .4rem;
  }
}
