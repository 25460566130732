select,
.custom-select {
  line-height: 1.39;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  @include background-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"/></svg>'
  );
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.8em auto, 100%;
  padding-right: 2rem;

  &:after {
    content: "\F107";
    font-family: "Font Awesome 5 Pro";
    color: #000;
    padding: 12px 8px;
    position: absolute;
    right: 0;
    top: 0;
    background: red;
    z-index: 1;
    text-align: center;
    width: 10%;
    height: 100%;
    pointer-events: none;
    box-sizing: border-box;
  }
  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    outline: none;
  }
  option {
    font-weight: normal;
  }
}
