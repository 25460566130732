
@import "~@/assets/scss/abstracts/variables";
.type-filter {
  &__toggle {
    display: block;
    padding: .75rem 3rem;
    width: 100%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    i {
      font-size: 1.4rem;
      position: absolute;
      left: .75rem;
    }
    &_title {
      font-size: .9rem;
      font-family: $fontPrimaryMedium;
      text-align: center;
    }
    .type-filter__count {
      font-size: .7rem;
    }
  }

  padding-bottom: 1.5rem;
  &__heading {
    font-size: .9rem;
    margin-top: 0;
    margin-bottom: .5rem;
  }
  .type__atparticle,
  .type__deadline,
  .type__priority_news {
    display: none;
  }

  .tag {
    font-size: .9rem;
    margin: 0.4rem 0.4rem 0.4rem 0;
  }

  &__count {
    display: inline-block !important;
    margin-left: 1rem;
    background: $blueMedium;
    color: #fff;
    border-radius: 50%;
    font-size: .7rem;
    width: 2.2em;
    height: 2.2em;
    padding: .4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
