@media screen and (min-width: $breakpoint-lg) {
  /*.offset-md-2 {
    margin-left: 0%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }*/

  /*.site-header .site-header__title {
    text-align: center;
  }
  .panel-mobile {
    box-shadow: none;
  }
  .panel-mobile__header {
    padding-left: 50px;
  }*/
  /*.site-header__announcements {
    .notification-prompt {
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
    }
    .site-header__announcements__toggle {
      position: absolute;
      top: 0.4rem;
      right: 0.8rem;
    }
  }*/
  .dropdown--open {
    .offcanvas {
      overflow-y: visible;
    }
  }
  /*.homepage {
    background: $backgroundColorGreyLight;
  }*/

  /*.hc-list__toggle {
    padding-right: 1.4rem;
  }*/

  /* Fix header/body z-index issue on subpages in desktop 
  .site-header,
  .site-header--fixed {
    position: absolute;
  }
  .body-section {
    z-index: 10;
  }
  .panel-mobile {
    position: relative;
    left: 0;
    width: 100%;
    &__header {
      padding-top: 0;
      min-height: auto;
      height: 0;
    }
    &__content {
      padding-top: 20px;
    }
  }*/
}
