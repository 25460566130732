.apartment-specs {
  margin-top: 2rem;
  .apartment-spec {
    margin-bottom: 2rem;
    .label,
    .value {
      display: block;
    }
    .label {
      font-size: 0.85rem;
      margin-bottom: 0.2rem;
    }
    .value {
      font-family: $fontPrimaryBold;
    }
  }
}
