.card-item {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  align-content: space-between;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  background: #fff;
  height: 100%;
  text-align: center;
  border: 1px solid $borderColorDefault;

  &__header {
  }

  &__body {
    padding: 0.8rem 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (min-width: $breakpoint-md) {
      padding: 1rem 1rem;
    }
  }

  &__title {
    font-family: $fontPrimaryMedium;
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  &__meta,
  &__date,
  &__headline {
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }

  &__cta {
    margin-top: 1.2rem;

    .button {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      width: 100%;
      max-width: 450px;
    }
    @media screen and (min-width: $breakpoint-md) {
      margin-top: 1.5rem;
      .button {
        width: auto;
        min-width: 250px;
      }
    }
  }

  /* Thumbnail */
  &__thumbnail {
    background-color: #f7f7f7;
    &--bg {
      height: 200px;
    }
    width: 100%;
    background-size: cover;

    img {
      width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
    }

    @media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-sm-down) {
      &--bg {
        height: 300px;
      }
    }
  }

  &__footer {
    font-size: 0.85rem;
    margin-top: auto;
  }
}
