.status-marker {
    font-family: $fontPrimaryBold;
    display: inline-block;
    padding-left: 1rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        background: #999;
        left: 0;
        top: 0;
        margin-top: .3rem;
    }

    &--draft {
        &:before {
            background: $orange;
        }
    }
    &--published {
        &:before {
            background: $green;
        }
    }
    &--scheduled {
        padding-left: 1.2rem;
        &:before {
            font-family: $fontIcon;
            content: "\F017";
            border-radius: inherit;
            background: transparent;
            margin-top: 0;
        }
    }
}