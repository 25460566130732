#app .mx-datepicker {
  display: block;
  width: 100%;
  font-size: 1em;

  .mx-calendar {
    color: $baseFontColor;
  }
  .mx-panel-date td.today {
    background: #80c6ea;
    color: inherit;
  }
  .mx-input {
    height: auto;
    line-height: inherit;
    border: 0px;
    box-shadow: none;
    padding: 0;
    &::placeholder {
      color: #878787;
    }
    &:focus {
      border-color: $greyBlueMidDark;
    }
  }
  .mx-input-append {
    padding: 0.1em;
  }
}
