
@import "~@/assets/scss/abstracts/_variables.scss";
.back-button {
  display: block;
  margin-top: 0;
  margin-bottom: 2rem;
  cursor: pointer;

  &--inline {
    display: inline-block;
    @media screen and (min-width: $breakpoint-sm) {
      float: left;
    } 
  }
}
a.back-button, button.back-button {
  color: $blueMedium;
  border-bottom-color: $blueMedium;
}
