
@import "~@/assets/scss/abstracts/variables";
.step-card {
  background-image: url("~@/assets/images/timeline-dash-left-white.svg");
  background-repeat: no-repeat;
  background-position: 33.33% 50%;
  background-size: contain;
  position: relative;
  &.bg--right {
    background-image: url("~@/assets/images/timeline-dash-right-white.svg");
    background-position: 66.66% 50%;
  }
  h1,h2,h3,h4,h5,h6, &__title {
    font-size: 1rem;
    font-family: $fontPrimaryBold;
  }
  &__description {
    font-size: .9rem;
  }
  &__date {
    i {
      margin-right: .3rem;
    }
    font-size: .85rem;
    top: .5rem;
    right: .5rem;
    color: #556a7b;
  }
  &__icon {
    font-size: 2.6rem;
    margin: .5rem 0 1rem 0;
  }
  text-align: center;
  position: relative;
  background-color: $blueLightest;
  padding: 1rem 2.5rem;
  position: relative;
  height: 100%;
  @media screen and (min-width: $breakpoint-lg) {
    padding: 1.2rem 3rem;
  }
}
