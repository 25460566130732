
  @import "~@/assets/scss/abstracts/_variables.scss";
  .like-button {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    position: relative;

    &__button {
      padding: .5rem;
    }

    &__count {
      display: inline-block;
      position: relative;
    }
    a.like-button__count {
      cursor: pointer;
      &:hover {
        margin-top: 1px;
        border-bottom: 1px solid $baseFontColor;
      }
    }

    &--active {
      color: $red;
      font-family: $fontPrimaryBold;
      .like-button__button {
        color: $red;
      }
      i {
        color: $red;
      }
      .like-button__count {
        color: $red;
      }
      a.like-button__count {
        &:hover {
          border-bottom: 1px solid $red;
        }
      }
    }

    &__users {
      position: absolute;
      display: block;
      z-index: 10;
      top: 2.5rem;
      width: 150px;
      max-height: 400px;
      margin-left: .3rem;
      word-wrap: break-word;
      text-transform: none;
      background: rgba($brandColorPrimary, .9);
      color: #fff;
      font-size: .7rem;
      border-radius: .4rem;
      line-height: 1.1;
      padding: .5rem;
      white-space: initial;

      span, div {
        font-size: .7rem;
        line-height: 1.2;
        margin: 0;
        padding: .15rem 0;
      }
    }
  }
