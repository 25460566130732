
@import "@/assets/scss/abstracts/_variables.scss";
.campaign {
  .campaign-partner {
    font-size: 0.9rem;
    padding: 0.3rem 0 0.2rem 0;
  }
  .campaign-duration {
    color: $fontColorLight;
    padding-top: 1.8rem;
    font-size: 0.7rem;
  }
}
@media screen and (min-width: $breakpoint-xxl) {
  .campaign-list--ads *[class*="col-"] {
    flex-basis: 33%;
  }
}
