.padding {
  &--default {
    padding: 1rem !important;
  }
  &--default-v {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  &--default-h {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  &--medium-v {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }
  &--medium-h {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }
  &--small-v {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }
  &--small-h {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }
  &--medium-large {
    padding: 1.5rem !important;
  }
  &--large {
    padding: 2rem !important;
  }

  &--no {
    padding: 0 !important;
  }
  &--no-v {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &--no-h {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &--no-top {
    padding-top: 0 !important;
  }
  &--no-bottom {
    padding-bottom: 0 !important;
  }
  &--no-left {
    padding-left: 0 !important;
  }
  &--no-right {
    padding-right: 0 !important;
  }
}
.margin {
  &--default-bottom {
    margin-bottom: 1rem;
  }
  &--large-bottom {
    margin-bottom: 2rem;
  }
  &--small-bottom {
    margin-bottom: 0.5rem;
  }
  &--default-top {
    margin-top: 1rem;
  }
  &--large-top {
    margin-top: 2rem;
  }
  &--small-top {
    margin-top: 0.5rem;
  }
  &--default-left {
    margin-left: 1rem;
  }
  &--large-left {
    margin-left: 2rem;
  }
  &--small-left {
    margin-left: 0.5rem;
  }
  &--default-right {
    margin-right: 1rem;
  }
  &--large-right {
    margin-right: 2rem;
  }
  &--small-right {
    margin-right: 0.5rem;
  }
  &--default-v {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  &--default-h {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  &--no {
    margin: 0 !important;
  }
  &--no-v {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &--no-h {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &--no-top {
    margin-top: 0 !important;
  }
  &--no-bottom {
    margin-bottom: 0 !important;
  }
  &--no-left {
    margin-left: 0 !important;
  }
  &--no-right {
    margin-right: 0 !important;
  }
  &--auto {
    margin: auto !important;
  }
  &--auto-top {
    margin-top: auto !important;
  }
  &--auto-bottom {
    margin-bottom: auto !important;
  }
  &--auto-left {
    margin-left: auto !important;
  }
  &--auto-right {
    margin-right: auto !important;
  }
}

@media screen and (max-width: $breakpoint-s-down) {
  .padding--no--s {
    padding: 0 !important;
  }
}

@media screen and (max-width: $breakpoint-sm-down) {
  .padding--no--sm {
    padding: 0 !important;
  }
  .margin-sm {
    &--default-bottom {
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: $breakpoint-md-down) {
  .padding--no--md {
    padding: 0 !important;
  }
}
@media screen and (max-width: $breakpoint-lg-down) {
  .padding--no--lg {
    padding: 0 !important;
  }
}

@media screen and (min-width: $breakpoint-s) {
  .padding--no--from-s {
    padding: 0 !important;
  }
}
@media screen and (min-width: $breakpoint-sm) {
  .padding--no--from-sm {
    padding: 0 !important;
  }
}
@media screen and (min-width: $breakpoint-md) {
  .padding--no--from-md {
    padding: 0 !important;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  .padding--no--from-lg {
    padding: 0 !important;
  }
}
