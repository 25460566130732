@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInWithSlideFromLeft {
  0% {
    transform: translateX(30%);
    opacity: 0;
  }
  60% {
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($baseFontColor, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($baseFontColor, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($baseFontColor, 0);
  }
}

@keyframes zoomBounce {
  0% {
    -webkit-transform: scale(0.5);
  }
  40% {
    -webkit-transform: scale(1.3);
  }
  40% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
  }
}