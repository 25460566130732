
@import "~@/assets/scss/abstracts/variables";
.welcome-to-yit-plus-modal {

  &__carousel-wrapper {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: $blueLightest;
  }

  @media screen and (max-width: $breakpoint-md) {
    .modal-container {
      max-width: 100vw;
    }
  }
  .modal__body {
    overflow-x: hidden;
  }

  .step-card {
    min-height: 17rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    h1,h2,h3,h4,h5,h6 {
      font-size: 1.6rem;
    }
  }

  .content-carousel__arrows {
    .right, .left {
      color: #fff;
    }
    .right {
      right: 0rem;
    }
    .left {
      left: 0rem;
    }
  }
}
