/**
 * Media elements
 */
 embed,
 iframe,
 object,
img {
    max-width: 100%;
    border: 0; 
}

img {
    font-style: italic;
    vertical-align: middle; 
}
img[height] {
    height: auto; 
}

figure {
    margin: 0; 
}

figcaption {
    font-size: 0.875rem; 
}

audio,
video,
iframe {
    margin: 0 0 1.5em; 
}

audio {
    min-width: 65%; 
}
audio:not([controls]) {
    display: none;
    height: 0; 
}

svg:not(:root) {
    overflow: hidden; 
}