
    @import "~@/assets/scss/abstracts/_variables.scss";
    .service-lounge-banner {
        width: 100%;
        background-image: url('~@/assets/images/service-lounge-bg-mobile.jpg');
        background-position: 100% 80%;
        background-size: 100%;

        &__inner {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            min-height: 250px;
            height: 100%;
            flex-shrink: 0;
            flex-grow: 1;
            justify-content: space-between;
        }

         h3 {
            font-family: $fontPrimaryLight;
            font-size: 1.3rem;
            margin-top: 0;
            width: 80%;
        }

        .button {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        @media screen and (min-width: $breakpoint-s) {
            h3 {
                width: 50%;
            }
            .button {
                max-width: 300px;
                min-width: auto;
            }
        }
        @media screen and (min-width: $breakpoint-md) {
            background-image: url('~@/assets/images/service-lounge-bg-desktop.jpg');
            background-position: 100% 40%;
            &__inner {
                padding: 1.5rem;
            }
            h3 {
                font-size: 1.5rem;
            }
            .button {
                margin-left: 0;
            }
        }

        @media screen and (min-width: $breakpoint-md) {
            background-position: 100% 35%;
        }
    }
