
@import "~@/assets/scss/abstracts/_variables.scss";
.image-preview-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.65);
  transition: opacity 0.3s ease;
  top: 0;
  left: 0;
}

.modal-enter .image-preview,
.modal-leave-active .image-preview {
  @media screen and (max-width: $breakpoint-md-down) {
    transform: scale(1.1);
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  @media screen and (max-width: $breakpoint-md-down) {
    transform: scale(1.1);
  }
}

.image-preview {
  
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  position: absolute;
  left: 50%;

  &__close {
    position: absolute;
    color: $brandColorPrimary;
    right: 1rem;
    top: 1rem;
    z-index: 102;
    background: #fff;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      margin: 0;
      &:before {
        margin-left: -.1rem;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      i:before {
        margin-left: 0;
      }
    }
  }

  img {
    top: 5rem;
    max-width: 90%;
    max-height: 75%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    height: auto;

    /*img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      object-fit: cover;
    }*/

    @media screen and (min-width: $breakpoint-md) {
      max-width: 75%;
      max-height: 75%;
    }
    @media screen and (min-width: $breakpoint-xl) {
      max-width: 1200px;
      max-height: 75%;
    }
  }

  &__navigation {
    position: absolute;
    height: 100%;
    width: 100%;
    button {
      position: absolute;
      color: #fff;
      border: 0;
      padding: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 5rem;
      z-index: 103;
    }
    &__prev {
      left: 5%;
    }
    &__next {
      right: 5%;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    max-width: 75%;
    max-height: 75%;
  }
   @media screen and (min-width: $breakpoint-xl) {
    max-width: 1200px;
    max-height: 75%;
  }
}
