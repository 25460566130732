ul.item-list,
ol.item-list {
  li {
    margin: 0;
    padding: 0;
  }
}
.item-list {
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  border-top: 1px solid $borderColorLight;

  > a,
  li > a,
  li > span,
  .item-list__item {
    display: flex;
    align-items: center;
    align-content: space-between;
    width: 100%;
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid $borderColorLight;
    transition: all 0.2s ease-in-out;
    font-family: $fontPrimaryMedium;

    .far,
    .fas,
    .fal {
      margin-left: 0;
      margin-right: 0.5rem;
      transition: all 0.2s ease-in-out;
    }

    .arrow-link {
      margin-left: auto !important;
      margin-right: 0;
      padding-left: 1rem;
      font-size: 1.2rem;
      transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
      background: $backgroundHoverColor;

      .far,
      .fas,
      .fal {
        transform: translateX(0.4rem);
        &.disable-transition {
          transform: translateX(0) !important;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    font-family: $fontPrimaryBold;
    margin: 0 0 0.2rem 0;
  }

  li.disable-transition {
    &:hover,
    &:active,
    &:focus {
      .far,
      .fas,
      .fal {
        transform: translateX(0) !important;
      }
    }
  }
}

//general status identificator
.item-status {
  font-family: $fontPrimaryRegular;
  font-size: 0.8rem;
  color: $fontColorLight;
  white-space: nowrap;
  .key {
    display: inline-block;
    padding-left: 0.3rem;
  }
}
