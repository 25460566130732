.tag {
  background: #555;
  color: #fff;
  font-size: 0.65rem;
  display: inline-block;
  letter-spacing: 0.05rem;
  padding: 0.4em 0.9em;
  margin: 0.2rem 0.2rem 0.2rem 0;
  border-radius: 12px;
  line-height: 1;
  text-transform: uppercase;

  &.open {
    background: green;
    color: #fff;
  }
  &.in-progress {
    background-color: #12718f;
  }
  &.closed {
    background-color: #f89406;
  }

  &.green {
    background: $green;
    color: #fff;
  }
  &.red {
    background-color: $red;
    color: #fff;
  }
  &.orange {
    background-color: $orange;
    color: #fff;
  }
  &.blue {
    background-color: $greyBlueMidDark;
    color: #fff;
  }
  &.blue-medium {
    background: $blueMedium;
    color: #fff;
  }
  &--small {
    font-size: 0.6rem;
  }
}

.tag-label-group {
  display: flex;
  li {
    margin: 0 .3rem .3rem 0;
  }
}

.tag-label {
  font-family: $fontPrimaryRegular;
  font-size: 0.7rem;
  display: inline-block;
  background: $blueLightest;
  padding: 0.1rem 0.3rem;
  vertical-align: top;
  margin-top: 0.1rem;
  text-transform: uppercase;
  
  &--lc {
    text-transform: none;
  }
  &__remove {
    display: inline-block;
    text-align: center;
    i {
      margin: 0 0 0 .3rem;
    }
  }
}