/**
 * Base content pick, which can be extended
 */
.document-pick {
  display: flex;
  align-items: space-between;
  align-content: space-between;
  position: relative;
  margin-bottom: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    font-family: $fontPrimaryRegular;
    margin: 0 0 0.2rem 0;
  }
  &__texts {
    overflow: hidden;
  }
  &__meta {
    padding-top: 0.3rem;
  }
  &__date {
    font-size: 0.7rem;
    margin-bottom: 0.3rem;
    font-family: $fontPrimaryLight;
  }
  &__title {
    font-family: $fontPrimaryRegular;
    padding-right: 1rem;
    line-height: 1.43;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
  &__icon {
    padding-right: 1em;
    font-size: 1.3em;
  }

  &__actions {
    font-size: 1.5rem;
    margin: auto 1rem;
    text-align: right;
    margin-left: auto;
    flex-shrink: 0;
  }
  &__filesize {
    margin-left: 0.8rem;
  }
  &__role {
    margin-left: 0.8rem;
    &.tag-label {
      font-size: .6rem;
      margin-top: -0.025rem;
      i {
        margin-right: .2rem;
      }
    }
  }
  &__folder {
    display: block;
    @media screen and (min-width: $breakpoint-sm) {
      display: inline;
      margin-left: 1.2rem;
    }
  }
  /* Thumbnail */
  &__icon-media {
    min-width: 30px;
    width: 30px;
    margin-right: 0.5rem;
    margin-top: 0.2rem;

    i {
      font-size: 20px;
    }
  }
}
