a,
.button--link,
button,
*[tabindex="0"] {
  &:focus {
    outline: 0;
    box-shadow: none;
    @media screen and (min-width: $breakpoint-lg) {
      outline: 1px dashed rgba(0, 0, 0, 0.2);
    }
  }
}
.button--primary,
.button--secondary {
  outline: 0;
}

input,
select,
textarea,
.custom-select {
  &:focus {
    outline: 1px solid $greyBlueDark;
  }
}
