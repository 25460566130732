
@import "~@/assets/scss/abstracts/_variables.scss";
.toolbar-link__wrapper {
  margin-bottom: .6rem;
}
.toolbar-link {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  border: 0;
  background: #fff;
  padding-right: 0.6rem;


  &__body,
  &__icon {
    padding: .7rem 0.3rem .6rem;
    font-size: 1rem;
    font-family: $fontPrimaryBold;
  }
  &__body {
    display: flex;
    align-items: center;
  }

    &__footer {
        border-top: 1px solid rgba(0,0,0,.1);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: .5rem 1rem .7rem 1rem;
        background: $white;

        small {
          font-size:.7rem;
        }
        .button-with-confirmation {
          margin-right: 1rem;
        }
    }

  &__icon {
    min-height: 2.8rem;
    height: auto;
    min-width: 4.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6rem;

    i {
      font-size: 1.2rem;
    }
  }

  &__arrow {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

    i {
      font-size: 1.3rem;
      transform: translateX(0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover,
  &:focus {
    .toolbar-link__arrow i {
      transform: translateX(0.4rem);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding-right: 1rem;

    &__body,
    &__icon {
      padding: 0.6rem 0.8rem;
    }
  }
}
