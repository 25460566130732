.article-page__content,
.main-content,
.table,
.booking-page {
  table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border: 1px solid $backgroundColorGreyMid;
    border-collapse: collapse;

    @media screen and (max-width: $breakpoint-sm-down) {
      display: block;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    th,
    td {
      padding: 0.75rem;
      vertical-align: top;
      border: 1px solid $backgroundColorGreyMid;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: 2px solid $backgroundColorGreyMid;
    }

    tbody + tbody {
      border-top: 2px solid $backgroundColorGreyMid;
    }
  }
  /* darker borders if grey-light bg */
  &.bg--grey-light {
    table {
      border-color: rgba(0, 0, 0, 0.2);
      th,
      td {
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  ul {
    list-style: inherit;
    padding-left: 1.5rem;
    li {
    }
  }
  ol {
    padding-left: 1.5rem;
  }

  &--responsive {
    table {
      min-width: 100%;
      display: block !important;
      overflow-x: auto !important;
      -ms-overflow-style: -ms-autohiding-scrollbar !important;
    }
  }
}
