.tree {
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: 0.6rem;
  }
  li,
  .tree__item {
    margin: 0;
    padding-left: 0.4rem;
    line-height: 1.2rem;
    border-left: 1px solid $borderColorDefault;
    border-bottom: 0;
  }
  li:last-child,
  .tree__item:last-child {
    //border-left:none;
  }

  li:before,
  .tree__item:before {
    position: relative;
    top: 2rem;
    height: 1rem;
    width: 0.7rem;
    color: white;
    border-bottom: 1px solid $borderColorDefault;
    content: "";
    display: inline-block;
    left: -0.4rem;
  }
  li:last-child:before,
  .tree__item:last-child:before {
    //border-left:1px solid $borderColorDefault;
  }
}
