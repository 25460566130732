//@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=434223d5-ad8e-4e6e-a701-f9d94be97d20");

//@import url("//fast.fonts.net/cssapi/434223d5-ad8e-4e6e-a701-f9d94be97d20.css");
/*
@font-face {
  font-family: "Helvetica Now Micro W01 XLight";
  src: url("../fonts/helvetica-now/4f46dcc0-1af9-4755-b03b-af6354dce291.eot?#iefix");
  src: url("../fonts/helvetica-now/4f46dcc0-1af9-4755-b03b-af6354dce291.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/89cb355d-869c-4577-826b-3dd4a117c241.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/3c8678fc-9766-488b-b4b7-cd0f322c169f.woff")
      format("woff"),
    url("../fonts/helvetica-now/29dd93ec-fc73-4b97-932b-78c37f1f3860.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 XLt It";
  src: url("../fonts/helvetica-now/2065faa9-0cd3-4b40-a326-89fde82112b0.eot?#iefix");
  src: url("../fonts/helvetica-now/2065faa9-0cd3-4b40-a326-89fde82112b0.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/4a242f1d-5e6e-4666-a82f-29dd1c2fe02a.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/b29d08b0-5801-4eb9-a2f2-8ed8fc10a6fb.woff")
      format("woff"),
    url("../fonts/helvetica-now/802bc099-5cfe-44c3-938d-da44ddef3375.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Light";
  src: url("../fonts/helvetica-now/8e51d773-a852-47f6-b4ec-161c9c218aba.eot?#iefix");
  src: url("../fonts/helvetica-now/8e51d773-a852-47f6-b4ec-161c9c218aba.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/39e28ff8-ed30-41dd-8248-84c7a8bb6604.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/b2c6749f-05a4-4c43-8945-b70c52ee0a3b.woff")
      format("woff"),
    url("../fonts/helvetica-now/a13af6a5-4c72-4cfa-bb7a-cca8e010bed7.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Lt It";
  src: url("../fonts/helvetica-now/385327db-5ba5-4f8e-af1e-2346a4630ba2.eot?#iefix");
  src: url("../fonts/helvetica-now/385327db-5ba5-4f8e-af1e-2346a4630ba2.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/0f4a6431-b235-46a3-b393-67e3fefb66b8.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/24401cf2-394e-40e6-97a1-c11f6bcff8de.woff")
      format("woff"),
    url("../fonts/helvetica-now/a0f5fb18-3d7e-4adf-a7c8-45a2d304cdd2.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Regular";
  src: url("../fonts/helvetica-now/b5e54101-64e1-4df3-96b8-35faa0b751ca.eot?#iefix");
  src: url("../fonts/helvetica-now/b5e54101-64e1-4df3-96b8-35faa0b751ca.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/b8421fb4-cff3-4723-b650-b08140b93f9b.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/1d80ac7a-9ba4-4037-94b2-64b1ecd8ee58.woff")
      format("woff"),
    url("../fonts/helvetica-now/998e3672-1e8e-4aed-ade0-53c1ace2429b.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Italic";
  src: url("../fonts/helvetica-now/fdaf2daa-5de5-43a5-a53d-d54bffd7e63e.eot?#iefix");
  src: url("../fonts/helvetica-now/fdaf2daa-5de5-43a5-a53d-d54bffd7e63e.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/362c7477-9528-42e4-8667-42af1cee352d.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/af4e3721-a2c1-4770-8918-bd76c793b055.woff")
      format("woff"),
    url("../fonts/helvetica-now/aaf93b95-653d-4c09-9f46-3466c3c160fe.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Medium";
  src: url("../fonts/helvetica-now/712ac22b-0028-4e46-aab0-675dde360730.eot?#iefix");
  src: url("../fonts/helvetica-now/712ac22b-0028-4e46-aab0-675dde360730.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/71ccd097-626f-43a3-87be-4017bafea408.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/86c5930d-4375-4eec-ac0d-fc923910d8a5.woff")
      format("woff"),
    url("../fonts/helvetica-now/86f168ac-3a7d-427c-9bc0-adaffc685ad6.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Md It";
  src: url("../fonts/helvetica-now/32a57f65-1640-43ab-8e52-2f0c5dfd4379.eot?#iefix");
  src: url("../fonts/helvetica-now/32a57f65-1640-43ab-8e52-2f0c5dfd4379.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/45ce6567-8c09-402e-9076-8ee8dd31cdf7.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/6cab0d72-79a1-4eb8-b314-f70968665de2.woff")
      format("woff"),
    url("../fonts/helvetica-now/1d4577ad-0337-4996-a3e4-ea3bdabe8958.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Bold";
  src: url("../fonts/helvetica-now/cef9e9a3-3027-4ce0-b536-464515612be4.eot?#iefix");
  src: url("../fonts/helvetica-now/cef9e9a3-3027-4ce0-b536-464515612be4.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/21dccc15-7fcb-4c2b-bc15-2ce6b1d9407b.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/dd6b493f-97c7-4d83-beb0-35ab4d4cb1d2.woff")
      format("woff"),
    url("../fonts/helvetica-now/c73ce6c7-3e2f-413c-b6f0-99b18ad4dc48.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 Bold It";
  src: url("../fonts/helvetica-now/c9260dfb-6132-4565-8694-55513f959226.eot?#iefix");
  src: url("../fonts/helvetica-now/c9260dfb-6132-4565-8694-55513f959226.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/27779963-ae87-46a5-948b-0593b496b294.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/5cf2b6f8-c47a-4456-97bc-29c3b7df035b.woff")
      format("woff"),
    url("../fonts/helvetica-now/a37ae64b-e8d5-482f-a5b5-89ecca38e01c.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 XBold";
  src: url("../fonts/helvetica-now/32217e10-c37f-48ff-b3fd-05d62392d19a.eot?#iefix");
  src: url("../fonts/helvetica-now/32217e10-c37f-48ff-b3fd-05d62392d19a.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/da377498-5048-4e3e-a8ac-736fabdcbe61.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/cc9f5799-3e0a-42a7-a5d8-9236e2ee939f.woff")
      format("woff"),
    url("../fonts/helvetica-now/f3c78f04-8fcc-4da6-a66d-e947dd27005a.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W01 XBd It";
  src: url("../fonts/helvetica-now/5e81e521-7b67-4d42-86ca-ed435628243e.eot?#iefix");
  src: url("../fonts/helvetica-now/5e81e521-7b67-4d42-86ca-ed435628243e.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/a1d36978-734e-4091-9e34-49ab5e32bf66.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/2d38b2ee-083b-462d-8862-a22c5f9a18cb.woff")
      format("woff"),
    url("../fonts/helvetica-now/33a064da-bd97-4529-97ae-deb37d0f68d9.ttf")
      format("truetype");
}

@font-face {
  font-family: "Helvetica Now Text W01 Regular";
  src: url("../fonts/helvetica-now/a62190d2-fdb7-49be-a6ef-3c7aa0bdc19c.eot?#iefix");
  src: url("../fonts/helvetica-now/a62190d2-fdb7-49be-a6ef-3c7aa0bdc19c.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/46dd84ea-bb96-46da-973b-d7fcca46437e.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/0fb90058-c064-40ed-8109-d1fd8633caa2.woff")
      format("woff"),
    url("../fonts/helvetica-now/22a7bedd-e428-4eab-938f-34d20396296a.ttf")
      format("truetype");
}

@font-face {
  font-family: "Helvetica Now Text W01 Bold";
  src: url("../fonts/helvetica-now/a6a98151-4f3e-4bb4-a103-a9b1a0bc9a04.eot?#iefix");
  src: url("../fonts/helvetica-now/a6a98151-4f3e-4bb4-a103-a9b1a0bc9a04.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/39ae88e8-7d53-4395-8f9d-f7a0d4060ec4.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/0c4f3bb0-d605-4462-9603-9163a560a536.woff")
      format("woff"),
    url("../fonts/helvetica-now/cd40f792-97b4-4378-af74-0529ac8a7687.ttf")
      format("truetype");
}*/

//@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=434223d5-ad8e-4e6e-a701-f9d94be97d20");
@font-face {
  font-family: "Helvetica Now Text W04 Regular";
  src: url("../fonts/helvetica-now/26b19bfa-2988-4eca-8dc3-2d1acb863cd8.eot?#iefix");
  src: url("../fonts/helvetica-now/26b19bfa-2988-4eca-8dc3-2d1acb863cd8.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/0fab17aa-71c4-46b5-9e69-f72da3f85c8a.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/709c6b31-9037-468f-88a6-4e659247799d.woff")
      format("woff"),
    url("../fonts/helvetica-now/365db674-7ff4-4c3f-93d6-ed8aab06c522.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Text W04 Medium";
  src: url("../fonts/helvetica-now/ae82b44b-61bd-46c6-9a48-ce5b1c3a932c.eot?#iefix");
  src: url("../fonts/helvetica-now/ae82b44b-61bd-46c6-9a48-ce5b1c3a932c.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/c9569098-d1a8-42f8-969e-3afea98a7a1d.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/1614b42f-5caa-43e5-a9df-0d7bb0c0a4db.woff")
      format("woff"),
    url("../fonts/helvetica-now/5da2bf1a-d4e5-44b7-8cad-fee8fff6da76.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Text W04 Bold";
  src: url("../fonts/helvetica-now/d1dcefbd-36aa-4a43-8581-37f774ac0eeb.eot?#iefix");
  src: url("../fonts/helvetica-now/d1dcefbd-36aa-4a43-8581-37f774ac0eeb.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/d653e3de-b378-448b-a2ee-bf2bb4a1a00a.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/a1c4cdc5-3b7e-4bda-8b2e-776b84520ca3.woff")
      format("woff"),
    url("../fonts/helvetica-now/93b2ff6c-a8bf-4a0c-b407-0098abb0fa60.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W04 Light";
  src: url("../fonts/helvetica-now/82d04e39-bf29-4451-a6af-eb2004fc7f5b.eot?#iefix");
  src: url("../fonts/helvetica-now/82d04e39-bf29-4451-a6af-eb2004fc7f5b.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/972c3d0d-d430-4aa6-8ea9-feef290ca9bd.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/bc587dd6-05cd-484f-80f6-c040b371fb87.woff")
      format("woff"),
    url("../fonts/helvetica-now/ee8df92b-1ad9-44c8-8f9b-aad7fddcda02.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W04 Regular";
  src: url("../fonts/helvetica-now/21c57887-e511-48ed-afdf-f7ebbbf6d00a.eot?#iefix");
  src: url("../fonts/helvetica-now/21c57887-e511-48ed-afdf-f7ebbbf6d00a.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/04ddb66b-b162-4040-b19d-18d50476b843.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/89c0568c-8f7c-4fb4-af9c-68b678d58ae4.woff")
      format("woff"),
    url("../fonts/helvetica-now/451044dc-0271-4ad0-87e9-e1d8b6d2707e.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W04 Medium";
  src: url("../fonts/helvetica-now/10c3ce85-c32c-4796-a144-df9c40652fd4.eot?#iefix");
  src: url("../fonts/helvetica-now/10c3ce85-c32c-4796-a144-df9c40652fd4.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/5a34ba6f-68ee-4f78-9183-2aa50085ba6a.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/c6915241-2bdd-4afb-aa61-69a7ba5fcdbc.woff")
      format("woff"),
    url("../fonts/helvetica-now/7bc69986-014a-4e58-8bb5-85184af376e4.ttf")
      format("truetype");
}
@font-face {
  font-family: "Helvetica Now Micro W04 Bold";
  src: url("../fonts/helvetica-now/b290873e-c352-4069-aad3-280af3fcbbbc.eot?#iefix");
  src: url("../fonts/helvetica-now/b290873e-c352-4069-aad3-280af3fcbbbc.eot?#iefix")
      format("eot"),
    url("../fonts/helvetica-now/5dbf2d24-e2ea-4704-9fe7-293ca67f545e.woff2")
      format("woff2"),
    url("../fonts/helvetica-now/f7f78d1e-7aa0-40f9-b8e8-ac96e9b21e95.woff")
      format("woff"),
    url("../fonts/helvetica-now/f0120b98-2890-455f-9991-cf17b2bc3ec7.ttf")
      format("truetype");
}
