.animation {
  &--fadein {
    animation: fadeIn 0.2s ease-in;
  }
  &--fadein-slow {
    animation: fadeIn 0.5s ease-in;
  }
  &--pulse {
    animation: pulse 0.3s;
  }
  &--zoom-bounce {
    animation: zoomBounce 0.2s ease-in;
  }
}
