
@import "~@/assets/scss/abstracts/_variables.scss";
.profile-icon {
  background: $brandColorPrimary;
  border-radius: 50%;
  display: flex;
  width: 2.6rem;
  height: 2.6rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  i,
  span {
    color: #fff;
    font-size: 0.85rem;
  }
  .icon--letter {
      font-family: $fontPrimaryBold;
  }
  .icon--yit {
    width: 100%;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 80%;
        height: auto;
    }
  }
  
  &--is-yit {
      background-color: $blueMedium !important;
  }

  &--small {
      width: 1.8rem;
    height: 1.8rem;
  }
}
