.carousel-wrapper .carousel__pagination {
  margin: 15px 0 0;
}

.carousel-wrapper li.carousel__pagination-item {
  position: relative;
  display: block;
  float: left;
  margin: 0 0.5rem;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.carousel-wrapper .carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border-radius: 50%;
  background-color: #25414d;
  text-indent: -999em;
  cursor: pointer;
  position: absolute;
  padding: 0;
  transition: background-color .3s ease;
}

.carousel-wrapper .carousel__pagination-button::after {
  background-color: transparent;
}

.carousel-wrapper ol.carousel__track {
  margin-left: 0px !important;
}

.carousel-wrapper .carousel__pagination-item {
  position: relative;
  display: block;
  float: left;
  margin: 0 0.5rem;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.carousel-wrapper .carousel__pagination-button:hover::after {
  background-color: transparent;
}

.carousel-wrapper .carousel__pagination-button--active::after {
  background-color: transparent;
}

.carousel-wrapper .carousel__pagination-button--active {
  background-color: transparent;
  border: 1px solid #25414d;
}

.carousel__icon {
  fill: #000000 !important;
  stroke: #6e6e6e;
}
