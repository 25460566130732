/** Needs position: relative in wrapping container */
.badge {
  position: absolute;
  z-index: 2;
  top: 0.2rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &--circle {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0; //fix for safari

    i {
      font-size: 0.85rem;
      margin: 0;
    }
  }

  &--right {
    right: 0.2rem;
  }
  &--left {
    left: 0.2rem;
  }
  &--remove {
    background: $red;
    color: #fff;
  }
}
