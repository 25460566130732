
@import "~@/assets/scss/abstracts/_variables.scss";
.likes-modal {
  color: $baseFontColor;
  font-family: $fontPrimaryRegular;
  cursor: auto !important;
  font-size: .9rem;

  .modal-container {
    max-width: 400px;
    cursor: auto !important;
    h1,h2,h3 {
      margin-top: 0;
    }
    .modal__body {
      margin-top: .45rem;
    }
  }
  .tag-label {
    color: inherit;
    margin-left: .5rem;
  }
}
