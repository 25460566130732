.bg {
  &--orange {
    background: $orange !important;
    color: #fff;
  }
  &--red {
    background: $red !important;
    color: #fff !important;
  }
  &--rose {
    background: $rose !important;
    color: #fff !important;
  }
  &--rose50 {
    background: $rose50 !important;
  }
  &--rose40 {
    background: $rose40 !important;
  }
  &--rose30 {
    background: $rose30 !important;
  }
  &--blue-light {
    background: $blueLight !important;
    color: #fff !important;
  }
  &--blue-medium {
    background: $blueMedium !important;
    color: #fff !important;
  }
  &--grey-blue-dark {
    background: $greyBlueDark !important;
    color: #fff !important;
  }
  &--grey-blue-mid-dark {
    background: $greyBlueMidDark !important;
    color: #fff !important;
  }
  &--grey-blue-medium {
    background: $greyBlueMedium !important;
    color: #fff !important;
  }
  &--grey-blue-mid-light {
    background: $greyBlueMidLight !important;
    color: #fff !important;
  }
  &--grey-blue-light {
    background: $greyBlueMedium !important;
    color: #fff !important;
  }
  &--grey-light {
    background: $greyLight !important;
  }
  &--grey-lighter {
    background: $greyLighter !important;
  }
  &--chateau-grey {
    background: $chateauGrey !important;
  }
  &--chateau-grey-mid-light {
    background: $chateauGreyMidLight !important;
  }
  &--chateau-grey-light {
    background: $chateauGreyLight !important;
  }
  &--primary {
    background: $brandColorPrimary !important;
    color: #fff !important;
  }
  &--secondary {
    background: $brandColorSecondary !important;
    color: #fff !important;
  }
  &--accent {
    background: $red !important;
    color: #fff !important;
  }
  &--grey {
    background: $backgroundColorGreyLight !important;
  }
  &--mid-grey {
    background: $backgroundColorGreyMid !important;
  }
  &--green {
    background: $green !important;
    color: #fff !important;
  }
  &--orange {
    background: $orange;
    color: $white;
  }
  &--black {
    background: #000 !important;
    color: #fff !important;
  }
  &--white {
    background: #fff !important;
  }
  &--opacity {
    background: rgba(255, 255, 255, 0.6) !important;
  }
  &--transparent {
    background: transparent !important;
  }
  &--hover-default {
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: $backgroundHoverColor !important;
    }
  }
  &--hover-light {
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: $backgroundHoverColorLight !important;
    }
  }
  &--alternative {
    background: $lighterFontColor;
  }
}
.color {
  &--orange {
    color: $orange !important;
  }
  &--red {
    color: $red !important;
  }
  &--rose {
    color: $rose !important;
  }
  &--rose50 {
    color: $rose50 !important;
  }
  &--rose40 {
    color: $rose40 !important;
  }
  &--rose30 {
    color: $rose30 !important;
  }
  &--blue-light {
    color: $blueLight !important;
  }
  &--blue-medium {
    color: $blueMedium !important;
  }
  &--grey-blue-dark {
    color: $greyBlueDark !important;
  }
  &--grey-blue-medium {
    color: $greyBlueMidDark !important;
  }
  &--grey-blue-mid-light {
    color: $greyBlueMidLight !important;
  }
  &--grey-blue-light {
    color: $greyBlueMedium !important;
  }
  &--grey-light {
    color: $greyLight !important;
  }
  &--green {
    color: $green !important;
  }
  &--chateau-grey {
    color: $chateauGrey !important;
  }
  &--chateau-grey-mid-light {
    color: $chateauGreyMidLight !important;
  }
  &--chateau-grey-light {
    color: $chateauGreyLight !important;
  }
  &--default {
    color: $brandColorPrimary !important;
  }
  &--primary {
    color: $brandColorPrimary !important;
  }
  &--secondary {
    color: $brandColorSecondary !important;
  }
  &--accent {
    color: $red !important;
  }
  &--black {
    color: #000 !important;
  }
  &--white {
    color: $white !important;
  }
  &--alternative {
    color: $lighterFontColor;
  }
}

/* White links for the darker backgrounds */
.bg--grey-blue-dark,
.bg--grey-blue-medium,
.bg--red,
.bg--primary {
  a {
    border-color: #fff;
    color: #fff;
  }
}
