.phase-navigation {
  //border-bottom: 3px solid $borderColorDefault;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &--full-width {
    max-width: inherit;
  }

  &__line {
    border-top: 3px solid $brandColorPrimary;
    width: 100%;
    height: 3px;
    position: absolute;
    top: 2.5rem;
  }
  ol {
    width: 100%;
    padding: 0;
    margin: 0 0 1.5rem 0;
    list-style: none;
    display: flex;
    flex: 1;
    position: relative;
    z-index: 1;
    li {
      flex-basis: 33%;
      width: 33%;
      text-align: center;
      padding: 0;
    }
  }
  &__number {
    padding: 1rem;
    display: flex;
    justify-content: center;
    span {
      background: $greyLight;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid $brandColorPrimary;
      font-size: 1rem;
      display: inline-block;
      font-family: $fontPrimaryBold;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__title {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: $greyBlueMedium;
    padding: 0.5rem 0 1rem 0;
  }

  li.phase--active {
    opacity: 1;
    .phase-navigation {
      &__number span {
        background: $blueMedium !important;
        border-color: $blueMedium !important;
        color: #fff;
      }
    }
  }
  li.phase--completed {
    opacity: 1;
    .phase-navigation {
      &__number span {
        background: $brandColorPrimary;
        border-color: $brandColorPrimary;
        color: #fff;
      }
    }
  }
  li.phase--disabled {
    opacity: 1;
    a {
      cursor: not-allowed;
    }
    .phase-navigation {
      &__number span {
        border-color: #999;
        color: #999;
        background-color: #f4f4f4;
      }
    }
  }

  .preview-campaign {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid $borderColorDefault;
  }
}
