
@import "~@/assets/scss/abstracts/_variables.scss";
.image-grid {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    padding: .5rem 0;
    position: relative;

    &__thumb {
        display: block;
        position: relative;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    button {
        z-index: 2;
    }

    &--thumbs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .image-grid__thumb {
        width: 10rem;
        height: 10rem;
        margin: .25rem;
        min-width: 100px;
        background-size: cover;

        @media screen and (min-width: $breakpoint-sm) {
          min-width: 200px;
        }
        @media screen and (min-width: $breakpoint-md) {
          min-width: 200px;
        }
        @media screen and (min-width: $breakpoint-lg) {
          min-width: 250px;
        }
      }
    }
  }
