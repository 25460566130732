
@import "@/assets/scss/abstracts/_variables.scss";
.contact-list__item {

  &__address {
    font-size: 0.9rem;
  }
  &__tel {
    a {
      margin-bottom: .4rem;
      font-size: .9rem;
    }
    .tel-icon {
      display: none;
      background: #fff;
      border-radius: 50%;
      padding: 0.3em;
      font-size: 0.9rem;
      margin-right: 0.3em;
      &:before {
        color: $greyBlueMidDark;
      }
    }
  }
  &__email {
    margin-bottom: .2rem;
    a {
      font-size: .9rem;
    }
  }
  
  &__email-cta {
    margin-top: 0.8rem;
    a {
      font-size: .9rem;
    }
  }

  &:last-child {
    margin-right: 0;
    padding-bottom: 0;
  }
}
