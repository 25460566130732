
@import "@/assets/scss/abstracts/_variables.scss";
.notification-group {
  z-index: 1;
  position: relative;
  &__scrollable {
    overflow-y: auto;
    height: 100%;
    max-height: 300px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @media screen and (min-width: $breakpoint-md) {
      max-height: 500px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    font-size: 0.95rem;
    padding: 1em 1rem;
  }
  &__body {
  }
  &__close {
    margin-left: auto;
    font-size: 1.5rem;
    color: #fff;
  }

  .notification-item {
    padding: 0.8rem 1rem 1rem 1rem;

    &__headline,
    &__date {
      font-size: 0.85rem;
      margin-bottom: 0.1rem;
    }
    &__title {
      font-family: $fontPrimaryBold;
    }
    &.notification-item__not-read {
      background-color: rgba(0, 0, 0, 0.1);
    }

    a {
      display: block;
    }
  }
  .notification-group__no-results {
    padding: 1rem 0;
    font-size: 0.85rem;
  }
}
