.panel {
  padding: 1rem;
  border-radius: 4px;
  text-align: left;
  border: 1px solid $borderColorDefault;

  h2,
  h3 {
    font-size: 1rem;
    font-family: $fontPrimaryMedium;

    &:first-child {
      margin-top: 0;
    }
  }

  &__setting-group {
    border: 1px solid $borderColorDefault;
    padding: 1rem;
    margin-bottom: 1rem;
    h2,
    h3,
    h4 {
      &:first-child {
        margin-top: 0;
      }
      font-size: 1rem;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: 1.5rem;
  }

  &.panel--alert {
    border-left: 0.6rem solid $red;
  }

  &.panel--success {
    border-left: 0.6rem solid $greyBlueMidLight;
  }
}
