
@import "~@/assets/scss/abstracts/_variables.scss";
.quick-link {
  display: flex;
  flex-grow: 1;
  border: 0;
  background: #fff;
  padding-right: 0.6rem;
  margin-bottom: 0.6rem;

  &__body,
  &__icon {
    padding: 0.6rem 0.3rem;
    font-size: 1rem;
    font-family: $fontPrimaryBold;
  }

  &__body {
    display: flex;
    flex-direction: column;
    word-break: break-word;
    
    .description {
      font-family: $fontPrimaryRegular;
      padding-top: 1rem;
    }
  }

  &__icon {
    min-height: 2.8rem;
    height: auto;
    min-width: 4.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.6rem;

    i {
      font-size: 1.2rem;
    }
  }

  &__arrow {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;

    i {
      font-size: 1.3rem;
      transform: translateX(0);
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover,
  &:focus {
    .quick-link__arrow i {
      transform: translateX(0.4rem);
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding-right: 1rem;
    margin-bottom: 1rem;

    &__body,
    &__icon {
      padding: 0.6rem 0.8rem;
    }
  }
}
