
.quick-link-item {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  &__drag, &__remove {
    margin-bottom: 1rem;
  }
}
