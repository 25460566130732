@import "~@/assets/scss/abstracts/_variables.scss";

/* Timeline styles */
.timeline__bg {
  background-color: $backgroundGrey;
  padding: 1rem 0 2rem 0;
  @media screen and (max-width: $breakpoint-lg-down) {
    margin-left: -20px;
    margin-right: -20px;
  }
  @media screen and (max-width: $breakpoint-md-down) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.timeline {
  max-width: 550px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

/* Timeline section styles */
.timeline-section {
  padding: 2rem 0 0 0;
  &__title {
    text-align: center;
    font-family: $fontPrimaryBold;
    color: $greyBlueMidLight;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    margin-bottom: 1.25rem;
  }
  .timeline-item:last-child {
    &:after {
      display: none !important;
    }
  }

  &__empty {
    .timeline-item__today {
      margin-bottom: 0;
    }
  }
}

/* Timeline item styles */
.timeline-item {
  position: relative;
  border: 1px solid $borderColorLight;
  background-color: #fff;
  margin-bottom: 1rem;

  > a {
    display: flex;
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding: .5rem 0 .5rem 0;
  }

  &__header, &__body, &__footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &__header {
    padding-top: .4rem;
    padding-bottom: .4rem;
    background-color: $greyBlueMidDark;
    color: #fff;
  }
  &__footer {
    font-size: .9rem;
    background-color: #fff;
    padding-top: .3rem;
    padding-bottom: .3rem;
    padding-left: 0;
    display: flex;
    &__right {
      display: flex;
      flex-basis: 50%;
      justify-content: flex-end;
      margin-left: auto;
      > div {
        padding-left: .8rem;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: .6rem;
      padding-bottom: .6rem;

      &__left {
        flex-basis: 60%;
        width: 60%;
      }
    }
  }
  &__title, h1,h2,h3,h4,h5,h6 {
    font-size: 1rem;
    font-family: $fontPrimaryBold;
    margin: .5rem 0 .2rem 0;
  }
  &__description {
    font-size: .85rem;
    font-family: $fontPrimaryRegular;
    margin: .8rem 0 .8rem 0;
  }
  &__body {
    position: relative;
    padding-top: .3rem;
    padding-bottom: .3rem;
    padding-left: 0;
    font-size: .9rem;
    background-color: #fff;

    @media screen and (min-width: $breakpoint-md) {
      padding-top: .6rem;
      padding-bottom: .6rem;
    }
  }
  &__date {
    font-size: .8rem;
    //color: $lighterFontColor;
  }

  &__thumbnail {
    margin: 0;

    &__image {
      background-size: cover;
      background-position: 50% 50%;
      height: 100%;
      width: 100%;
    }

    .content-pick__thumbnail--bg,
    .content-pick__highlight {
      min-width: 80px;
      max-width: 80px;
      min-height: 80px;
      height: 100%;
      margin-right: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      .content-pick__thumbnail--bg,
      .content-pick__highlight {
        min-width: 120px;
        max-width: 120px;
        min-height: 120px;
        margin-right: 1.2rem;
      }
    }
  }

  /*&:after {
    content: '';
    width: 3px;
    height: 2rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-left: 2px dashed $lighterFontColor;
  }*/

  &__yit-tag {
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
  }

  .tag {
    min-width: 80px;
    text-align: center;
  }

  @media screen and (min-width: $breakpoint-sm) { 
    .tag {
      min-width: 100px;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    .tag {
      min-width: 130px;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  /* Colors */
  &.timeline-item--event,
  &.timeline-item--service,
  &.timeline-item--deadline,
  &.timeline-item--task {
    .tag {
      background: $rose50;
      color: $greyBlueDark;
    }
    .timeline__item__inner:before {
      background: $rose50;
    }
  }

  &.timeline-item--announcement,
  &.timeline-item--status_update,
  &.timeline-item--priority_news,
  &.timeline-item--marketing,
  &.timeline-item--news {
    .tag {
      background: $chateauGreyMidLight;
      color: $greyBlueDark;
    }
    .timeline__item__inner:before {
      background: $greyBlueMedium;
    }
  }

  &--state-disabled {
    opacity: 0.6;
    .timeline__item__title {
      text-decoration: line-through;
    }
  }
}

.load-more {
  display: block !important;
  text-align: center;
  &.load-more__newer {
    margin: 0 auto 1rem auto;
  }
  &.load-more__later {
    margin: 2rem auto 1rem auto;
  }
}