body {
  height: 100vh;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.body-section {
  flex: 1 0 auto;
}
.site-footer {
  flex-shrink: 0;
}
