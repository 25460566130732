
  @import "~@/assets/scss/abstracts/_variables.scss";
  .attach-images {
    display: flex;
    align-items: center;

    label {
      display: flex;
    }

    &__button {
      padding: .8rem;
      cursor: pointer;
      i {
        font-size: 1.3rem;
      }
      input[type="file"] {
        position: absolute;
        height: 1px;
        width: 1px;
        &:focus {
          outline: 0;
          border: 0;
        }
      }
    }
  }
