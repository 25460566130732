@import "util/colors";
@import "util/padding";
@import "util/typography";
@import "util/media";
@import "util/borders";
@import "util/animation";

[v-cloak] {
  display: none;
}
/* Reseting lists */
ul {
  list-style: none;
  margin: 0.5rem 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0 0 0.2rem 0;
  }
  &.inline {
    li {
      display: inline-block;
      margin-right: 0.2rem;
    }
  }
}
.text-center {
  text-align: center;
  @media screen and (min-width: $breakpoint-sm) {
    &--from-sm {
      text-align: center;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &--from-md {
      text-align: center;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &--from-lg {
      text-align: center;
    }
  }

  @media screen and (min-width: $breakpoint-xl) {
    &--from-xl {
      text-align: center;
    }
  }
}
.text-left {
  text-align: left;
  @media screen and (min-width: $breakpoint-sm) {
    &--from-sm {
      text-align: left;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &--from-md {
      text-align: left;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &--from-lg {
      text-align: left;
    }
  }

  @media screen and (min-width: $breakpoint-xl) {
    &--from-xl {
      text-align: left;
    }
  }
}
.text-right {
  text-align: right;
  @media screen and (min-width: $breakpoint-sm) {
    &--from-sm {
      text-align: right;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    &--from-md {
      text-align: right;
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    &--from-lg {
      text-align: right;
    }
  }
  @media screen and (min-width: $breakpoint-xl) {
    &--from-xl {
      text-align: right;
    }
  }
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.center--hv {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.border--no {
  border: 0 !important;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.position {
  &--inherit {
    position: inherit;
  }
  &--static {
    position: static;
  }
  &--absolute {
    position: absolute;
  }
  &--relative {
    position: relative;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .float-left-from-sm {
    float: left;
  }
  .float-right-from-sm {
    float: right;
  }
}
@media screen and (min-width: $breakpoint-md) {
  .float-left-from-sm {
    float: left;
  }
  .float-right-from-sm {
    float: right;
  }
}
@media screen and (min-width: $breakpoint-lg) {
  .float-left-from-lg {
    float: left;
  }
  .float-right-from-lg {
    float: right;
  }
}
@media screen and (min-width: $breakpoint-xl) {
  .float-left-from-xl {
    float: left;
  }
  .float-right-from-xl {
    float: right;
  }
}
