
@import "~@/assets/scss/abstracts/_variables.scss";

.border-bottom__h {
  border-bottom: 1px solid $borderColorLight;
}
.comments__no-comments {
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1rem;
}
.public-messages {
  background: $greyLight;
  margin-top: 2em;

  .comments__title {
    font-family: $fontPrimaryBold;
    margin-bottom: 1rem;
    padding-left: 1rem;
    padding-top: 1.5rem;
  }
  .message-item {
    &:first-child {
      background: white;

      .apartment-label {
        background: $blueLightest;
      }
      .message-item__sender {
        font-family: $fontPrimaryBold;
      }
    }
  }
  .message-item {
    padding-left: 1rem;
    padding-right: 1rem;
    &__sender {
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
    &__time {
      font-family: $fontPrimaryLight;
      font-size: 0.7rem;
    }
  }
}
.conversation-label-yit {
  max-width: 50px;
  vertical-align: baseline;
}
