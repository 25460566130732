
@import "~@/assets/scss/abstracts/_variables.scss";
.select-signature {
    max-width: 300px;
    text-align: left;
    padding-bottom: 1rem;

    .input-group {
        margin-top: .3rem;
        font-size: .9rem;
    }

    @media screen and (min-width: $breakpoint-md) {
        display: flex;
        align-items: center;
        min-height: 52px;

        .input-group {
            margin: 0;
        }

        &__toggle {
            white-space: nowrap;
            padding-right: 1rem;
        }
    }
}
