
/* spinner */

$color: $brandColorPrimary;

.loader-icon {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.5rem 0;
  text-align: center;
  font-size: 1rem;
  opacity: 0.8;

  /* Spinner */
  .spinner {
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 1em;
      height: 1em;

      border: 0.2em solid $color;
      border-radius: 50%;
      animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $color transparent transparent transparent;
    }
    div div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.color--white .spinner {
    div {
      border-color: #fff transparent transparent transparent;
    }
  }

  /* Rectangle animation */
  .rect {
    width: 2em;
    height: 1em;

    > div {
      background-color: $color;
      height: 100%;
      width: 0.2em;
      margin: 0 0.025em;
      display: inline-block;

      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .rect3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }

    &.color--white div {
      div {
        background-color: #fff;
      }
    }
  }

  /* modifiers */
  &--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  &--block {
    display: block;
    margin: 1rem auto;
  }
  &--medium {
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    .spinner div {
      border-width: 0.15em;
    }
    .rect {
      height: 2em;
      div {
        width: 0.23em;
        margin: 0.04em;
      }
    }
  }
  &--large {
    font-size: 4rem;
    width: 4rem;
    height: 4rem;
    .spinner div {
      border-width: 0.1em;
    }
    .rect {
      height: 1.5em;
      div {
        width: 0.17em;
        margin: 0.04em;
      }
    }
  }
  &--flex {
    height: 0;
    width: 0;
    .spinner {
      display: flex;
      align-items: center;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
