.site-header {
  background: $brandColorPrimary;
  color: #fff;
  padding: 0 1rem;
  z-index: 15;
  min-height: 57px;

  &__inner {
    display: flex;
    align-items: center;
    padding: 0.4rem 1rem;
    margin-top: 0.1rem;
    @media screen and (max-width: $breakpoint-lg-down) {
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: $breakpoint-lg) {
      .site-header__navigation,
      .site-header__right {
        flex-basis: 30%;
      }
    }
  }

  &__left {
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    img {
      width: auto;
      height: 16px;
      @media screen and (min-width: $breakpoint-md) {
        height: 20px;
      }
    }
  }

  &__title {
    flex-basis: 40%;
    text-align: center;
    justify-content: center;
    font-size: .9rem;

    &--admin {
      i {
        margin-right: 0.5rem;
      }
    }
  }

  &__right {
    flex-basis: 50%;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;

    > div {
      margin-left: 1.2rem;
    }
  }

  &__announcements,
  .profile-menu {
    &__toggle {
      padding: 0.3rem 0.5rem;
      position: relative;
      font-size: 1.5rem;
    }
    i,
    .fas,
    .far,
    .fal {
      color: #fff;
    }
  }
  
  .profile-menu {
    &__dropdown:before {
      right: 6.5rem;

      @media screen and (min-width: $breakpoint-lg) {
          right: 2.6rem;
      }
    }
  }

  &__announcements {
    .notification-prompt {
      right: .75rem;
      &:before {
        right: 3.28rem;
      }
      @media screen and (min-width: $breakpoint-md) {
        &:before {
          right: 3.3rem;
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        right: -1rem;
        &:before {
          right: 1.1rem;
        }
      }
    }
  }

  .profile-menu__toggle {
    font-size: 1.4rem;
  }

  &__title {
    > a {
      color: #fff;
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    &--top-by-height {
      top: 4.05rem;
    }
    &--top-by-height-with-top-margin {
      top: 4.8rem;
    }
    @media screen and (min-width: $breakpoint-md) {
      min-height: 62px;
      &--top-by-height {
        top: 3.85rem;
      }
      &--top-by-height-with-top-margin {
        top: 4.5rem;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      &--top-by-height {
        top: 3.85rem;
      }
      &--top-by-height-with-top-margin {
        top: 4.2rem;
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &__navigation {
      display: none;
      flex-basis: 0%;
    }
    &__right {
      position: relative;
    }
  }
}
.site-header--fixed--top-by-height.site-overlay {
  top: 4.1rem !important;
  @media screen and (min-width: $breakpoint-md) {
    top: 3.85rem !important;
  }
}

.fixed-header-scroll-fix {
  scroll-margin-top: 62px;
  scroll-snap-margin-top: 62px;
}
