.vue-treeselect {
  .vue-treeselect__control {
    width: 100%;
    color: $baseFontColor;
    font-family: $fontPrimaryRegular;
    border-radius: 0;
    border-color: $borderColorDefault;
    padding: 0.6em 0.8em;

    .vue-treeselect__placeholder {
      color: #878787;
      top: -0.3rem;
    }
    &:focus {
      border-color: $greyBlueMidDark;
    }
  }
  .vue-treeselect__label {
    margin: 0 0 0 0.2rem;
    display: block;
    padding: 0;
  }

  &.vue-treeselect--has-value {
    .vue-treeselect__control {
      padding: 0.35em 0.8em;
    }
    &:not(.vue-treeselect-open) {
      .vue-treeselect__multi-value {
        max-height: 10rem;
        overflow: auto;
      }
    }
  }

  &--single {
    input[type="text"].vue-treeselect__input {
      background-color: transparent;
      height: 0.5rem;
    }
    &.vue-treeselect--has-value {
      input[type="text"].vue-treeselect__input {
        height: 1rem;
      }
    }
  }

  .vue-treeselect__checkbox--indeterminate {
    border-color: #e0e0e0;
    background: #fff;
  }

  .vue-treeselect__x-container {
    position: relative;
    z-index: 1;
  }
}
