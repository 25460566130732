.container-area {
  /* modifiers */
  padding: 0;

  /* elements */
  &__heading {
    font-size: 1rem;
    margin-top: 0;
    font-family: $fontPrimaryRegular;
  }
  &__date,
  &__headline {
    font-size: 0.85rem;
    margin-bottom: 0.4rem;
  }
}

.body-section {
  padding-top: 6rem;
  /*
  @media screen and (max-width: $breakpoint-lg-down) {
    > .container-fluid {
      padding: 0 !important;
    }
  }
*/
  @media screen and (min-width: $breakpoint-md) {
    padding-top: 6rem;
  }
}

.container-fluid {
  max-width: 1500px;

  @media screen and (min-width: $breakpoint-sm) {
    &--to-sm {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding-left: 20px;
    padding-right: 20px;

    &.narrow {
      max-width: 650px;
    }

    &--to-md {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &--to-lg {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    max-width: 1500px;
  }
}

.page-container {
  //background: #fff;
  min-height: 100%;
}

/* content section under the layout column */
.page-section {
  margin-bottom: 2rem;

  &__header,
  &__footer {
    .read-more,
    .archive-link {
      color: $blueMedium;
      @media screen and (max-width: $breakpoint-sm-down) {
        font-size: 0.85rem;
      }
    }
  }

  &__header {
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .read-more,
    .archive-link {
      margin-left: auto;
      @media screen and (max-width: $breakpoint-sm-down) {
        max-width: 50%;
      }
    }

    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      font-size: 1rem;
      font-family: $fontPrimaryBold;
      @media screen and (min-width: $breakpoint-xl) {
        font-size: 1.1rem;
      }
    }
  }
  &__body {
    background: #fff;
    padding: 1rem;

    p:first-child {
      margin-top: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }

    .panel-mobile__content {
      padding-left: 0;
      padding-right: 0;
    }

    .alert:first-child {
      margin-top: 0;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding: 1.5rem;
    }
    @media screen and (min-width: $breakpoint-xl) {
      padding: 1.8rem;
    }
  }

  &__footer {
    padding: 0.5rem 0;
  }

  &--align-right {
    text-align: right;
    .page-section__header {
      display: block;
      text-align: right;
    }
  }
}
