.border--default {
  border: 1px solid $borderColorDefault !important;
}
.border--color-default {
  border-color: $borderColorDefault !important;
}
.border--color-default-top {
  border-top-color: $borderColorDefault !important;
}
.border--color-default-bottom {
  border-bottom-color: $borderColorDefault !important;
}
.border--color-default-left {
  border-left-color: $borderColorDefault !important;
}
.border--color-default-right {
  border-right-color: $borderColorDefault !important;
}

.border--lightest {
  border: 1px solid $borderColorLightest !important;
}
.border--lightest {
  border-color: $borderColorLightest !important;
}
.border--lightest-top {
  border-top-color: $borderColorLightest !important;
}
.border--lightest-bottom {
  border-bottom-color: $borderColorLightest !important;
}
.border--lightest-left {
  border-left-color: $borderColorLightest !important;
}
.border--lightest-right {
  border-right-color: $borderColorLightest !important;
}


.border--light {
  border: 1px solid $borderColorLight !important;
}
.border--color-light {
  border-color: $borderColorLight !important;
}
.border--color-light-top {
  border-top-color: $borderColorLight !important;
}
.border--color-light-bottom {
  border-bottom-color: $borderColorLight !important;
}
.border--color-light-left {
  border-left-color: $borderColorLight !important;
}
.border--color-light-right {
  border-right-color: $borderColorLight !important;
}

.border-color--base {
  border-color: $baseFontColor;
}

.border--no {
  border: 0px !important
}
.border--top {
  border-top: 0px !important
}
.border--bottom {
  border-bottom: 0px !important
}
.border--right {
  border-right: 0px !important
}
.border--left {
  border-left: 0px !important
}