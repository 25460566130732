$baseColor: $borderColorDefault;
$accentColor: $greyBlueDark;

.c-cb {
  padding-left: 2em;
  position: relative;
}

.c-cb > input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-left: 0;
  margin-top: 0;
  opacity: 0.00001;
  position: relative;
  vertical-align: top;
  z-index: 2;
}

/**
 * Make sure the label is only as wide as the
 * inner text requires it to be.
 * The label should not be a block element
 * or run 100% width of its container.
 * Why would someone expect to click
 * white space on the opposite side of the
 * screen to have a checkbox checked?
 */
.c-cb > label {
  display: inline-block;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

/**
 * Note, the :before pseudo-element is the new
 * "bounds" or "box" of the checkbox.
 * It must be the same height, width and
 * position of the native checkbox element.
 */
.c-cb > label:before,
.c-cb > input[type="checkbox"] {
  height: 1.125em;
  left: 0.125em;
  width: auto;
}

/**
 * Base styles for use on both
 * pseudo elements.
 */
.c-cb > label:before,
.c-cb > label:after {
  border: 1px solid;
  content: " ";
  position: absolute;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    transform 0.2s ease-in-out;
}

/**
 * Styles for the custom box boundary.
 */
.c-cb > label:before {
  border-color: $baseColor;
  border-radius: 2px;
  box-shadow: 0;
  height: 1.325em;
  left: 0.125em;
  top: 0;
  width: 1.325em;
}

/**
 * This recreates the "check" mark.
 */
.c-cb > label:after {
  border: 0;
  border-bottom: 3px solid $accentColor;
  border-right: 3px solid $accentColor;
  height: 0.825em;
  left: .53em;
  top: 0.1429rem;
  transform-origin: center center;
  transform: rotate(45deg) scale(0);
  width: 0.5em;
}

/**
 * ** Defining States **
 */
.c-cb > input:checked ~ label:before {
  border-color: transparent;
  box-shadow: 0 0 0 1px $baseColor;
}

.c-cb > input:focus ~ label:before {
  border-color: transparent;
box-shadow: 0 0 0 1px $accentColor;
  outline-offset: 2px;
  outline: 2px solid transparent;
}

.c-cb > input:checked ~ label:after {
  transform: rotate(45deg) scale(1);
}

.c-cb > input:checked:focus ~ label:after {
  border-color: $accentColor;
}

.c-cb > input[disabled] ~ label {
  opacity: 0.625;
}

/* placeholder design until a better required
   design pattern can be created */
.c-cb > input:invalid ~ label:before {
  border-color: #f00;
  box-shadow: 0 0 0 1px #f00;
}

.c-cb > input:invalid:focus ~ label:before {
  box-shadow: 0 0 0 2px #f00;
}

.c-cb input:invalid ~ [aria-hidden="true"] {
  color: #f00;
}
