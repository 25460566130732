
@import "~@/assets/scss/abstracts/_variables.scss";

.timeline-item__today {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-left: 11px solid $greyBlueMidDark;
  border-right: 11px solid $greyBlueMidDark;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  &__title {
    font-size: 1.1rem;
    letter-spacing: .1rem;
    margin-bottom: .2rem;
    text-transform: uppercase;
  }

  @media screen and (max-width: $breakpoint-lg-down) {
    margin-left: -20px;
    margin-right: -20px;
  }
  @media screen and (max-width: $breakpoint-md-down) {
    margin-left: -15px;
    margin-right: -15px;
  }
}
