
@import "@/assets/scss/abstracts/_variables.scss";
.site-footer {
  color: #fff;
  padding: 1rem 0 0;
  border-top: 2px solid $blueMedium;
  margin-top: 1rem;

  @media screen and (min-width: $breakpoint-lg) {
    padding-top: 2rem;
  }

  a {
    color: #fff;
    border-bottom-color: #fff;
  }

  &__logo {
    max-width: 100px;
    text-align: center;
    margin-top: .4rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: $breakpoint-lg) {
      position: absolute;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__copy {
    font-size: 0.85rem;
    background-color: #1e3540;
    margin-top: 1rem;
    padding: 1rem;

    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      text-align: left;
    }
  }
  &__copyright {
    padding: 0.5rem 0;
    font-size: 0.75rem;
    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      //text-align: left;
    }
  }
  .corporation-navigation {
    list-style: none;
    font-family: $fontPrimaryLight;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (min-width: $breakpoint-lg) {
      margin-top: .2rem;
    }

    li {
      display: inline-block;
      margin: 0 1rem 0.5rem 1rem;
      a {
        display: block;
        padding-bottom: 0.2rem;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      li {
        margin-bottom: 0;
      }
    }
  }

  /**
   * Style override in footer
  */
  .contact-list {
    justify-content: flex-start;
    padding-top: 1rem;
    font-size: 0.9rem;
    &__item {
      &__tel {
        margin: 0.2rem 0 0.4rem 0;
        .yit-icon,
        .fas,
        .far,
        .fal {
          transition: all 0.2s ease-in-out;
          //display: inline-block;
        }
        &:hover {
          .yit-icon,
          .fas,
          .far,
          .fal {
            background-color: $borderHoverColor;
          }
        }
      }
    }

    @media screen and (min-width: $breakpoint-sm) {
      &__item {
        margin-bottom: 1.8rem;
        width: 100%;
        max-width: 45%;
        flex-basis: 45%;
      }
    }

    @media screen and (min-width: $breakpoint-md) {
      padding-top: 0;
    }

    @media screen and (min-width: $breakpoint-lg) {
      &__item {
        max-width: 25%;
        flex-basis: 25%;
        padding-right: 3%;
      }
    }
  }
}
