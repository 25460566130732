/**
 * Base styles
 */
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="password"],
button,
textarea,
.textarea--styled,
.custom-select,
.attachment-picker,
.mx-datepicker,
select {
  font-family: $fontPrimaryRegular;
  padding: 0.6em 0.8em;
  border: 1px solid $borderColorLight;
  border-radius: 0;
  font-size: 1em;
  color: $greyBlueMidDark;
  background: #fff;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $lighterFontColor;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $lighterFontColor;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $lighterFontColor;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $lighterFontColor;
}
::placeholder {
  color: $lighterFontColor;
}

input[type="text"],
input[type="search"],
input[type="number"],
input[type="tel"],
.button,
textarea,
.textarea--styled,
.custom-select,
select,
input[type="file"],
.attachment-picker {
  outline: 0;
  transition: all 0.2s ease-in;
  &::placeholder {
    color: #878787;
  }
  &:focus {
    border: 1px solid $greyBlueMidDark;
    outline: 0;
    box-shadow: none;
  }
}

label:focus {
  outline: 0;
}

.input-group--textarea-styled {
  position: relative !important;
}
.textarea--styled {
  margin: 0;
  position: relative;
  z-index: 1;
  min-height: 100px;
  background: transparent;
  &__placeholder {
    position: absolute;
    z-index: 0;
    top: 0;
    color: #878787;
  }
}

input,
textarea,
.textarea--styled,
button {
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

/* Checkboxes & radio buttons */
.checkbox,
.radio {
  input {
    margin-bottom: 0.25rem;
  }
}

/**
 * Input & button groups
 */
.input-group,
.button-group {
  margin: 1rem 0;
}
.button-group {
  //when buttons are in top of each other
  .text-center {
    button,
    input[type="button"] {
      margin-left: auto;
      margin-right: auto;
    }
  }
  //when buttons are next to each other
  button {
    margin: 0.2rem 0.5rem;
  }

  &:not(.text-center) {
    button:first-child {
      margin-left: 0;
    }
  }

  &.text-center {
    button {
      margin: 0.2rem 0.5rem;
    }
  }

  button {
    text-transform: uppercase;
  }
}
.button-group--stacked {
  button,
  .button,
  input[type="button"] {
    border-left-width: 0px !important;
    &:hover {
      border-left-width: 0px !important;
    }
    &:first-child {
      border-left-width: 1px !important;
    }
  }
}

label.label {
  font-size: 0.7rem;
  text-transform: uppercase;
  color: rgba(40, 71, 84, 0.69);
  font-family: $fontPrimaryMedium;
}

.input-group {
  text-align: left;

  label {
    display: block;
    font-size: 0.9em;
    margin-bottom: 0.5rem;

    &.label {
      font-size: 0.7rem;
      text-transform: uppercase;
      color: rgba(40, 71, 84, 0.69);
      font-family: $fontPrimaryMedium;
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="password"],
  select,
  .textarea--styled,
  textarea {
    display: block;
    width: 100%;
    transition: all 0.2s ease-in;
  }

  select {
    min-width: 100px;
  }

  &.checkbox,
  &.radio {
    input,
    label {
      display: inline;
    }
    label {
      /*margin-left: 0.2rem;*/
    }
  }

  /* Smaller input */
  &--small {
    font-size: 0.9em;
  }

  &--repeatable {
    border: 1px solid $borderColorDefault;
    padding: 1rem;
  }

  /* Inline form group */
  @media screen and (min-width: $breakpoint-md) {
    &--inline {
      label {
        display: inline-block;
        margin-right: 1rem;
      }
      input,
      select {
        display: inline-block !important;
        width: auto !important;
      }
      &.input-group--checkbox {
        display: inline;
        .checkbox {
          display: inline;
        }
      }
    }
    &--checkbox {
      .instructions-text {
        padding-left: 2rem;
      }
    }
  }

  &--small {
    font-size: 0.7rem;
  }
}

input:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
  color: #999;
}

.instructions-text {
  font-size: 0.8rem;
  color: $fontColorLight;
  margin-top: 0.3rem;
}

/* Validation error */
.has-error {
  input[type="text"],
  input[type="number"],
  input[type="tel"],
  select,
  .textarea--styled,
  textarea,
  .custom-select {
    border-color: $red !important;
  }
}
input:required:invalid:focus,
select:required:invalid:focus,
textarea:required:invalid:focus {
  border-color: $red !important;
}

//hide tiny mce nag
.tox-notification.tox-notification--in {
  display: none !important;
}

.field-group {
  padding-bottom: 1rem;
}

.input-button-group {
  display: flex;
  .button,
  button,
  input[type="submit"] {
    white-space: nowrap;
  }
  .button--secondary {
    border: 1px solid $borderColorLight;
    border-left: 0;
    outline: 0;
    box-shadow: none;
  }
}

/* Prevent iOS zooming issue  */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="tel"],
  input[type="search"],
  textarea,
  select,
  .custom-select,
  input[type="file"],
  .search-input__input {
    font-size: 16px !important;
  }
}

/* Custom components */
@import "forms/radio-input";
@import "forms/checkbox-input";
@import "forms/select-box";
@import "forms/date-picker";
@import "forms/tree-select";
@import "forms/fieldset";
