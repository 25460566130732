
@import "~@/assets/scss/abstracts/_variables.scss";
#sub-comment-form {
  scroll-margin-top: 82px;
  scroll-snap-margin-top: 82px;
}
.comment-wrapper {
  margin-bottom: 1rem;
  @media screen and (min-width: $breakpoint-md) {
    margin-bottom: 0;
  }
}
