.search-form {
  font-size: 0.9rem;
  h2 {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-family: $fontPrimaryBold;
    cursor: pointer;

    > i {
      margin-right: 0.3rem;
    }

    button {
      margin-left: auto;
      text-decoration: none;
      border-bottom: 0;
      i {
        font-size: 2rem;
      }
    }
  }
  .button-group {
    button {
      margin-right: 0.8rem;
    }
  }
  .datepicker-spacer {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: -0.25rem;
    margin-top: 0.5rem;
  }
}
