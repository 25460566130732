
@import "~@/assets/scss/abstracts/_variables.scss";
.timeline-item--step {
  //height: 325px;
  background-color: transparent;
  border: 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .fal, .fas, .far, .step-heart img {
    animation: zoomBounceSmall 2.5s ease-in .2s infinite;
  }
  .fal, .fas, .far {
    font-size: 2.7rem;
    color: $red;
  }
  &:after {
    display: none;
  }
}

.timeline-item {
  &__image-as-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    img {
      height: 325px;
      width: 169px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@keyframes zoomBounceSmall {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

