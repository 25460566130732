$inactiveTabBorderColor: #d4d4d4;

.tabs {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 0 auto 2rem auto;
  align-items: stretch;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab,
  li {
    /*flex: 0 0 auto;*/
    flex: 1;
    margin: 0;
    padding: 0;
    a {
      display: block;
      cursor: pointer;
      text-align: center;
      height: 100%;
      padding: 0.86rem 1rem;
      color: $greyBlueDark;
      font-size: 0.85rem;
      border-bottom: 3px solid transparent;
      font-family: $fontPrimaryLight;
      border-bottom: 3px solid $inactiveTabBorderColor;
      background-position: left bottom;
      background-size: 1px 3px;
      background-repeat: repeat-x;
    }

    .router-link-exact-active,
    .router-link-active,
    &.is-tab-selected a,
    &.selected a {
      font-weight: 400;
      font-family: $fontPrimaryBold;
      border-bottom: solid 5px $rose;
      background: none;
    }
  }

  .tab--disabled {
    a {
      color: $greyBlueMedium;
      cursor: not-allowed;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  &--normal {
    max-width: 100%;
    margin-left: 0;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: solid 1px $inactiveTabBorderColor;

    .tab,
    li {
      flex: inherit;
      a {
        border-bottom-width: 5px;
      }
    }
  }

  /* Full width version */
  &--full-width {
    //justify-content: center;
    .tab,
    li {
      margin-right: 0.4rem;
      max-width: 50%;
      a {
        border-bottom-width: 0.45rem;
      }
      .router-link-exact-active,
      .router-link-active,
      &.is-tab-selected a,
      &.selected a {
        border-bottom-width: 0.45rem;
      }
    }
    li:last-child {
      margin-right: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      max-width: inherit;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      li,
      .tab a {
        transition: all 0.15s ease;
      }
    }
  }
}
