
@import "@/assets/scss/abstracts/_variables.scss";

.hc-list {
  background-color: $greyBlueMedium;
  position: relative;
  .yit-icon {
    &.fa-angle-down {
      display: inline-block;
    }
    &.fa-angle-up {
      display: none;
    }
  }
  &--open {
    .yit-icon {
    &.fa-angle-down {
      display: none;
    }
    &.fa-angle-up {
      display: inline-block;
    }
  }
  }
  .hc-list__toggle {
    max-width: max-content;
    width: auto;
    outline: none;
  }
  &__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;

    @media screen and (max-width: $breakpoint-md-down) {
      height: 60px;
    }
  }

  &__toggle {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .5rem 0 .6rem 0;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;

    &--inactive {
      cursor: text;
    }

    @media screen and (max-width: $breakpoint-md-down) {
      justify-content: left;
      padding-left: 1.2rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      max-width: 500px;
    }
  }

  &__current {
    margin-right: 0.4rem;
    position: relative;
    padding-right: .5rem;
    line-height: 1;
    text-align: left;

    @media screen and (max-width: $breakpoint-md-down) {
      width: 100%;
    }

    &__toggle-icon {
      position: relative;
      top: .05rem;
      margin-left: 0;
      margin-right: 1rem;
    }

    &__apt {
      margin-left: 0.3rem;
    }

    .current__hc {
      line-height: 1;
      &__name, &__address { 
        display: block;
      }
      &__name {
        font-size: .8rem;
      }
      &__address {
        font-size: 1rem;
        margin-bottom: .5rem;
      }

      @media screen and (min-width: $breakpoint-md) {
        &__name, &__address {
          font-size: .85rem;
          display: inline;
        }
      }
    }
  }

  .yit-icon {
    font-size: 1.4em;
    margin-left: 0.2rem;
    position: relative;
    top: 2px;
    @media screen and (min-width: $breakpoint-md) {
      top: 4px;
    }
  }

  &__dropdown {
    text-align: center;
    position: fixed;
    z-index: 10;
    width: 95%;
    max-height: 80%;
    height: auto;
    z-index: 10;
    left: 0;
    top: 9.5rem;
    color: #fff;
    transition: all 0.2s ease-in-out;
    max-width: 600px;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: "";
      top: -8px;
      left: auto;
      right: auto;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 1rem 1rem 1rem;
      border-color: transparent transparent $greyBlueMedium transparent;
      transition: all 0.2s ease-in-out 0.5s;
    }

    a {
      color: #fff;
    }

    .input-group {
      margin: 0;
      max-width: none;
    }

    li,
    .hc-list__item {
      font-size: 1rem;
      padding: 1rem 1rem 0.5rem 1rem;
      color: #fff;
      border-bottom: 1px solid #fff;
      text-align: left;

      &.active {
        font-weight: 700;
      }
      &:last-child {
        margin-bottom: 0;
      }

      .c-radio > label {
        padding-bottom: 0;
      }

      .hc-apt {
        margin-left: 0.5rem;
      }
      .hc-name {
        font-size: 0.7rem;
        color: rgba(255, 255, 255, 0.7);
        @media screen and (min-width: $breakpoint-sm) {
          display: inline-block;
          margin-left: 1rem;
        }
      }

      &__apartments {
        font-size: 0.9em;
        margin-left: 1.7rem;
        padding-top: 1rem;
        .c-radio {
          margin-bottom: 1.1em;
        }
      }

      @media screen and (min-width: $breakpoint-md) {
        padding: 1em 1em 0.5rem 1em;
      }
    }

    &__content {
      overflow-y: auto;
      height: 100%;
      max-height: 400px;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    &__footer {
      padding: 1.5rem 0;
      .button {
        padding: 0.8rem 4rem;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &__dropdown {
      top: 7.5rem;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &__dropdown {
      max-height: none;
      li,
      .hc-list__item .hc-list__item__inner,
      .hc-list__item__apartments {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      .hc-list__item__apartments {
        padding-left: 1.7rem;
      }
    }
  }

  &--open {
    .site-overlay {
      top: 8.35rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      .site-overlay {
        top: 6.55rem;
      }
    }
  }
}
