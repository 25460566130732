.campaign ol, .campaign ul {
    margin-left: 20px;
}

.campaign__content ul {
    list-style-type: disc;
}

.campaign__content ol, .campaign__content ul {
    list-style-position: inside;
}

.campaign__content ol, .campaign__content ul, .main-content ol, .main-content ul, .table ol, .table ul {
    list-style: inherit;
    padding-left: 1.5rem;
}