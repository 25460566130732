
@import "~@/assets/scss/abstracts/_variables.scss";
.action-link {
  border-top: 1px solid $borderColorLight;
  border-bottom: 0;
  align-items: center;
  margin-bottom: 0;
  &:last-child {
    border-bottom: 1px solid $borderColorLight;
  }
}
