/**
 * Act as a full screen panel in mobile devices
 */
@media screen and (max-width: $breakpoint-lg-down) {
  .panel-mobile {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    box-shadow: 1rem 0 10rem -2px rgba(0, 0, 0, 0.1);

    &__header {
      //position: fixed;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 101;
      width: 100%;
      padding: 1rem;
      min-height: 50px;

      font-size: 1.15rem;
      .yit-icon {
        font-size: 1.1em;
        position: relative;
        top: 1px;
        margin-right: 0.3em;
      }

      a {
        cursor: pointer;
      }
      button,
      .back-button {
        border-bottom: 0 !important;
      }

      &.is-fixed {
        position: fixed;
      }
    }
    &__content {
      min-height: 60px;
      padding-top: 60px; //add padding top as much as the header height is
      padding-bottom: 1rem;
      /*border-bottom: 1px solid $backgroundColorGreyLight;*/
    }
    &__footer {
      min-height: 50px;
      border-top: 1px solid $backgroundColorGreyLight;
    }
  }
}

.panel-mobile {
  @media screen and (min-width: $breakpoint-md) {
    &__header {
      min-height: 80px;
    }
    &__content {
      padding-top: 80px;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    &__content {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    &__header {
      display: none;
    }
    &__content {
      padding: 0;
    }
  }
  @media screen and (min-width: $breakpoint-xl) {
    &__content {
      padding: 0;
    }
  }
}
