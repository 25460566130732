.alert {
  display: flex;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.8rem;
  margin: 1rem 0 1.5rem 0;
  align-items: center;

  i {
    margin-right: 1rem;
    font-size: 1.2em;
  }

  p:first-child {
    margin-top: 0.5rem;
  }
  p:last-child {
    margin-bottom: 0.5rem;
  }

  div {
    ul,
    ol,
    p {
      display: inherit;
      font-size: 0.9rem;
    }
  }

  ul,ol {
    padding-left: 3rem;
  }

  p a, div a {
    border-bottom-color: transparent;
  }

  &.alert--error {
    border: 1px solid $rose50;
    background: $rose50;
    > i {
      color: $red;
    }
  }
  &.alert--success {
    border: 1px solid $greenLight;
    background: $greenLight;
    > i {
      color: $green;
    }
  }
  &.alert--notice {
    border: 1px solid $orangeLight;
    background: $orangeLight;
    > i {
      color: $orange;
    }
  }
  &.alert--reminder {
    border: 1px solid $rose;
    border-left: .6rem solid $rose;
    background: #fff;
  }

  &.alert--success,
  &.alert--notice,
  &.alert--error {
    a {
      color: #fff;
      text-decoration: underline;
    }
    .button--link {
      border-bottom-color: #fff;
    }
  }

  &.alert--error.bg--rose,
  &.alert--error.bg--rose30,
  &.alert--error.bg--rose40,
  &.alert--error.bg--rose50
  {
    a {
      color: $baseFontColor;
    }
  }

  &.alert--important {
    background-color: #fff;
    border-width: 0;
  }

  &.alert--info {
    background: #fff;
    border-color: $baseFontColor;
    color: $baseFontColor;
    //align-items: flex-start;
    i {
      font-size: 1.4rem;
    }
    &.align--top {
      align-items: flex-start;
      i {
        margin-top: 0.6rem;
      }
    }
  }
}
.error-text {
  font-size: 0.9rem;
  color: $red;
  margin-top: 0.2rem;
  margin-top: 0.3rem;
  display: block;
}

.bg--white {
  .alert--info {
    border: 1px solid $borderColorLight;
  }
}
