.thumbs {
  display: flex;
  &__item {
    position: relative;
    height: 80px;
    width: 80px;
    background: #efefef;
    margin: 0.4rem 0.8rem 1rem 0;

    img:after {
      content: '';
      color: transparent;
      position: absolute;
      height: 100%;
      width: 100%;
      background: #efefef;
      left: 0;
      top: 0;
    }
  }

  &__remove {
    position: absolute;
    top: -.5rem !important;
    &.badge--right {
      right: -.5rem !important;
    }
    &.badge--left {
      left: -.5rem !important;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &--medium {
      .thumbs__item {
        height: 120px;
        width: 120px;
      }
    }

    &--large {
      .thumbs__item {
        height: 150px;
        width: 150px;
      }
    }
  }

  &--is-draggable {
    .thumbs__item {
      cursor: move;
    }
  }
}
