
@import "../../../assets/scss/abstracts/variables";
.admin-toolbar {
  /*background: #313131;

  padding: 0.5rem 0.5rem;
  border-radius: 5px;*/
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.8rem 0;
  font-size: 0.9rem;

  > .button,
    .button-with-confirmation button,
    .button-with-confirmation .button {
    background-color: transparent;
    border-color: transparent !important;
    margin-right: 1rem;
    font-size: .9rem;
    //color: #fff;

    span,
    i {
      transition: all 0.15s ease-in;
      //color: #fff;
    }

    &:hover,
    &:focus {
      border-color: rgba(255, 255, 255, 0.2);
      background: transparent !important;
    }
    /*@media screen and (min-width: $breakpoint-lg) {
      &:hover {
        span,
        i {
          transform: scale(1.15);
        }
      }
    }*/
  }

  a,
  a:hover,
  a:focus {
    color: #fff;
  }

  .ident {
    height: 1rem;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 6px;
    margin-left: 5px;
  }

  &--vertical {
    flex-direction: column;
    align-items: flex-start !important;

     > .button,
    .button-with-confirmation button,
    .button-with-confirmation .button {
      text-align: left;
      margin: .5rem 0;
    }
  }
}
.button-group {
  button {
    margin-right: 0.5rem;
  }
}
