/* Offcanvas core styles */
.offcanvas {
  position: fixed;
  color: $baseFontColor;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  //transition: left 0.3s ease, box-shadow 0.3s ease;
  z-index: 999;
  background: #fff;
  padding: 3rem 0 1.5rem 0;
  width: 100%;
  max-width: 450px;
  box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox

  &--right {
    left: inherit;
    right: 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 0.83rem;
    display: block;
    left: 0;
    outline: none;
    box-shadow: 3px 0 8px rgba(0, 0, 0, 0.15);
    padding-top: 0;
  }

  &.is-visible {
    display: block;
    left: 0;
    outline: none;
    box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    position: fixed;
  }

  &__toggle {
    color: #fff;
  }

  &__header {
    padding: 0.8rem;
  }

  &__close {
    position: absolute;
    background: transparent;
    right: 0;
    top: 0;
    padding: 1.5rem;
    i {
      display: block;
      width: 100%;
      font-size: 1.5rem;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

/* Site overlay */
.site-overlay {
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  transition: visibility 0s ease, opacity 0.2s ease;

  &.site-overlay {
    visibility: visible;
    opacity: 1;
    background: rgba(255, 255, 255, 0.8);
    transition-delay: delay 0s;
  }
}
