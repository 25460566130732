
@import "~@/assets/scss/abstracts/variables";
@import "~@/assets/scss/components/_carousel.scss";

.carousel {
  width: 100%;
  margin:auto;
}

.carousel__prev, .carousel__next{
  color: white;
}
