
@import "~@/assets/scss/abstracts/_variables.scss";
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  &.modal-bg--red .modal-container {
    background: $red;
    color: $white;
  }
}

.button__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.modal-container {
  width: 95%;
  position: relative;
  max-width: 800px;
  margin: 0px auto;
  padding: 20px 30px;
  padding: .8rem .5rem;
  background-color: $greyLight;
  transition: all 0.3s ease;
  .modal-container {
    background-color: darken($greyLight, 0.7) !important;
  }
  @media screen and (min-width: $breakpoint-md) {
    padding: 1.2rem 1.5rem;
  }
}

.modal__header {
  display: flex;
  margin-top: 0;
}
.modal__body {
  margin: 1rem;
  overflow: auto;
  max-height: 70vh;
  .button {
    margin: 0.5rem auto;
    display: inline-block;
    max-width: 600px;
    width: 80%;
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-mask--full {
  .modal-container {
    padding: 0;
    background-color: transparent;
    max-width: 1200px;
  }
  .modal-body {
    margin: 0;
    padding: 0;
  }
  .button__close {
    color: #fff;
    font-size: 1.3rem;
    padding: 0;
    position: fixed;
    position: absolute;
    right: 0rem;
    top: -0.8rem;
    z-index: 99;
    background: #000;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 0;
    }
  }
  .modal__body {
    max-height: 100%;
  }
  .modal-footer {
    display: none;
  }
}
.modal-header {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.1rem;
    font-family: $fontPrimaryBold;
    text-align: center;
  }
}

.fit-to-content {
  .modal-container {
    padding: 0;
  }
  .modal__body {
    margin: 0;
    padding: 0;
  }
  .modal-footer {
    display: none !important;  
  }
  .button__close {
    background-color: #fff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }
}
.color-mode--dark {
  &.modal-mask {
    background-color: rgba($greyBlueDark, 0.5);
  }

  .modal-container {
    background: #fff !important;
  }
}
