.message-item {
  margin: 1rem 0 0 0;
  padding-bottom: 2rem;
  text-align: left;
  border-bottom: 1px solid $borderColorDefault;
  border-radius: 4px;

  &--me {
    //text-align: right;
    //margin-left: auto;
    .message-item__content {
      text-align: left;
    }
  }

  &__sender {
    font-size: 0.8rem;
  }
  &__time {
    font-size: 0.8rem;
  }
  &__content {
    margin-top: 0.5rem;
    font-size: 0.9rem;
  }
}
