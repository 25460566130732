$cRadioColor: $greyBlueDark;

.c-radio {
  position: relative;

  > input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    opacity: 0.00001;
    z-index: 2;
  }

  > label {
    display: block;
    padding: 0.1em 0.25em 0.5em 1.8em;
  }

  > input[type="radio"],
  > label:before,
  > label:after {
    border: 1px solid;
    height: 1.125em;
    left: 0.125em;
    position: absolute;
    top: 0.22em;
    width: 1.125em;
    box-sizing: border-box;
    transition: all 0.2s ease-in;
  }

  > input[type="radio"],
  > label:before,
  > label:after {
    border-radius: 100%;
    content: "";
  }

  > label:after {
    border-color: #565656;
  }

  > label:before {
    border-color: transparent;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }

  > input:checked ~ label:before {
    border-color: transparent;
    box-shadow: 0 0 0 1px $cRadioColor;
  }

  > input:focus ~ label:before {
    border-color: transparent;
    box-shadow: 0 0 0 2px lighten($cRadioColor, 70%);
  }

  > input:checked ~ label:after {
    border-width: 0;
    box-shadow: inset 0 0 0 5px $cRadioColor;
    background-color: $cRadioColor;
  }

  > input[disabled] ~ label {
    opacity: 0.5;
  }

  @media screen and (-ms-high-contrast: active) {
    > input:checked ~ label:before {
      background: window;
      border: 6px solid buttonface;
      box-shadow: none;
    }
  }

  &.c-radio--light {
    > label {
      color: #fff;
    }
    > label,
    label:after,
    label:before {
      color: #fff;
      border-color: #fff;
    }
    & > input:checked ~ label:after {
      -webkit-box-shadow: inset 0 0 0 5px #fff;
      box-shadow: inset 0 0 0 5px #fff;
      background: #fff;
    }
  }
}
@media screen and (min-width: 786px) {
  .c-radio-group--inline {
    .c-radio {
      display: inline-block;
      > label {
        display: inline-block;
        position: relative;
        margin: 0 1em 1em 0;
      }
    }
  }
}
