
@import "~@/assets/scss/abstracts/_variables.scss";

.error-page-icon {
  font-size: 3rem;
  margin: 1rem 0;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 6rem;
  }
}
