
@import "~@/assets/scss/abstracts/_variables.scss";

.sub-comment-list {
    padding: 1rem 0 0 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-left: 3px solid rgba(255,255,255,.8);
    position: relative;
    font-size: .9rem;
    &:before {
        content: "";
        position: absolute;
        border: 1px solid #fff;
    }

    @media screen and (min-width: $breakpoint-md) {
        margin-top: 0;
        padding-top: 0;
    }
}
