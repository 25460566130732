
@import "@/assets/scss/abstracts/_variables.scss";
.quick-link-editor {
  position: relative;
  z-index: 10;
  border: 2px dashed $blueLightMedium;
  padding: 3.75rem 1rem 1rem 1rem;
  .close-editor {
    position: absolute;
    top: -.25rem;
    right: 0;
    padding: 1rem;
    font-size: 1.2rem;
    i {
      position: relative;
      top: .15rem;
      font-size: 1.4rem;
    }
    span {
      font-size: .95rem;
    }
  }
}
.quick-links {
  &__list {

  }
}
