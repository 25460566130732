.tooltip {
    position: absolute;
    z-index: 10;
    display: block;
    margin: .3rem;
    word-wrap: break-word;
    opacity: 1;
    bottom: 1.2rem;
    left: 50%;
    transform: translateX(-50%);
    text-transform: none;
    font-size: .8rem;
    transition: margin .1s ease-in;
    animation: fadeIn .15s ease-in;

    // Wrapper for the tooltip content
    .tooltip-inner {
        width: max-content;
        min-width: 150px;
        max-width: 250px;
        display: block;
        padding: .25rem .5rem;
        color: #fff;
        text-align: center;
        background-color: $greyBlueDark;
        border-radius: .25rem;
        font-size: .85rem;

        @media screen and (min-width: $breakpoint-md) {
            max-width: 400px;
        }
    }
  
    &.show { 
        opacity: 1;
    }
  
    .arrow {
        position: absolute;
        display: block;
        width: .8rem;
        height: .4rem;
        left: 50%;
        transform: translateX(-50%);
  
      &::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
      }
    }

    &--top {
        padding: .4rem 0;

        .arrow {
            bottom: 0;

            &::before {
            top: 0;
            border-width: .4rem (.4rem / 2) 0;
            border-top-color: $greyBlueDark;
            }
        }
    }

    &--right {
        padding: 0 .4rem;

        .arrow {
            left: 0;
            width: .4rem;
            height: .4rem;

            &::before {
                right: 0;
                border-width: (.4rem / 2) .4rem (.4rem / 2) 0;
                border-right-color: $greyBlueDark;
            }
        }
    }

    &--bottom {
        padding: .4rem 0;

        .arrow {
            top: 0;

            &::before {
            bottom: 0;
            border-width: 0 (.4rem / 2) .4rem;
            border-bottom-color: $greyBlueDark;
            }
        }
    }

    &--left {
        padding: 0 .4rem;

        .arrow {
            right: 0;
            width: .4rem;
            height: .4rem;

            &::before {
            left: 0;
            border-width: (.4rem / 2) 0 (.4rem / 2) .4rem;
            border-left-color: $greyBlueDark;
            }
        }
    }


    &--white {
        .tooltip-inner {
            background-color: #fff;
            color: $greyBlueDark;
        }
        &.tooltip--top {
            .arrow {
                &::before {
                    border-top-color: #fff !important;
                }
            }
        }
        &.tooltip--bottom {
            .arrow {
                &::before {
                    border-bottom-color: #fff !important;
                }
            }
        }
        &.tooltip--left {
            .arrow {
                &::before {
                    border-left-color: #fff !important;
                }
            }
        }
        &.tooltip--right {
            .arrow {
                &::before {
                    border-right-color: #fff !important;
                }
            }
        }
    }
}
.tooltip-container {
    position: relative;
    display: inline-block;
    button {
        font-size: 1rem;
        padding: 0;
        display: inline-block;
        margin: 0 .2rem;
        i {
            position: absolute;
            top: 0;
        }
    }

    &--white {
        button, button i {
            color: #fff;
        }
    }
}