.article-page {
  &__image {
    text-align: center;

    &__caption {
      display: block;
      font-size: .85rem;
      color: $lighterFontColor;
      padding: .4rem 0 .2rem 0;
    }
  }
  &__content {
    margin-bottom: 1rem;
    @media screen and (max-width: $breakpoint-lg-down) {
      overflow-x: hidden;
    }
  }

  &__location {
    font-family: $fontPrimaryMedium;
    padding-top: 1rem;
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: 1rem;
    }
  }

  &__add-to-calendar {
    font-size: .9rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  ul,
  ol {
    margin-left: 20px;
  }

  &__footer {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
  }

  @media screen and (min-width: $breakpoint-md) {
    &__image {
      margin: 1rem 0 1.5rem 0;
    }
    &__action-links {
      margin: 1rem 0;
      .row {
        align-items: flex-start;
      }
    }
    .notification-bar {
      margin: 1rem 0 1.5rem 0;
    }

    &__footer {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
