body.login-page {
  background-color: #fff;
}
body.layout-admin-frontpage,
body.layout-admin-default {
  background-color: #f0f0f0;
  .back-button, a.back-button, button.back-button {
    color: inherit;
    font-family: $fontPrimaryBold;
    border-bottom-color: inherit;
  }
}