html,
body {
  font-size: $fontSizeBaseMobile;
}
body {
  font-family: $fontPrimaryRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $greyBlueMidDark;

  line-height: 1.5;
}

p {
  margin: 1rem 0;
}

.lead,
.ingress {
  font-family: $fontPrimaryLight;
  font-size: 1rem;
  line-height: 1.5;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 1.15rem;
  }
}

b,
strong,
.text--bold {
  font-weight: 400;
  font-family: $fontPrimaryBold;
}
html[lang="ru"] {
  h1,
  h2,
  h3,
  b,
  .text--bold,
  strong {
    font-weight: 700;
  }
}

.text--light {
  font-family: $fontPrimaryLight;
}

small {
  font-family: $fontPrimaryLight;
  font-size: 0.8rem;
}

a {
  text-decoration: none;
  color: $baseFontColor;
  > i {
    margin-right: 0.4rem;
  }
  &.unstyled {
    text-decoration: none;
    border-bottom: 0;
  }
}

a.arrow-link,
button.arrow-link {
  &:after {
    font-family: $fontIcon;
    content: "\F054";
    margin-left: 0.3rem;
    position: absolute;
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    &:after {
      transform: translateX(0.4rem);
    }
  }
}

div.arrow-link,
button.arrow-link,
span.arrow-link {
  i,
  .far,
  .fas,
  .fal {
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
  }

  &:hover,
  &:active,
  &:focus {
    i:not(.disable-transition),
    .far,
    .fas,
    .fal {
      transform: translateX(0.4rem);
    }
    .disable-transition {
      transform: translateX(0) !important;
    }
  }
}

/* Archive and read more link purposes */
div.read-more,
div.archive-link {
  text-align: center;
  margin: 0 0 1rem 0;
  @media screen and (min-width: $breakpoint-md) {
    text-align: right;
  }
}
.read-more a,
a.read-more,
.archive-link a,
a.archive-link,
button.button--link.read-more,
button.button--archive {
  color: $blueMedium;
  font-size: 0.85rem;
  display: inline-block;
  border-bottom: 1px solid $blueMedium;
  padding-bottom: 0.1rem;
  transition: all 0.2s ease-in-out;
}

.read-more a,
a.read-more,
button.read-more,
.archive-link a,
a.archive-link,
button.archive-link {
  font-family: $fontPrimaryRegular !important;
}

/* link states to normal text links and link buttons */
p a,
.button--link,
button.button--link,
.button--archive,
button.button--archive,
.read-more a,
a.read-more,
.archive-link a,
a.archive-link {
  color: $blueMedium;
  border-bottom: 1px solid $blueMedium;
  transition: all 0.2s ease-in-out;

  &:active,
  &:focus {
    border-bottom-color: $borderHoverColor;
  }

  @media screen and (min-width: $breakpoint-lg) {
    &:hover {
      border-bottom-color: $borderHoverColor;
    }
  }
}

a.color--default {
  border-bottom-color: $baseFontColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontPrimaryBold;
  margin: 1rem 0;
}
h1 {
  margin-top: 0;
}
h2 {
  font-size: 1.2rem;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 1.45rem;
  }
}
h3 {
  font-size: 1.1rem;
  @media screen and (min-width: $breakpoint-md) {
    font-size: 1.2rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.page-title {
    font-size: 1.2rem;
    margin-bottom: 0;
    font-family: $fontPrimaryBold;
    @media screen and (min-width: $breakpoint-md) {
      font-size: 1.3rem;
      line-height: 1.4;
    }
  }
  &.sub-page-title {
    font-size: 1rem;
    font-family: $fontPrimaryBold;
    @media screen and (min-width: $breakpoint-lg) {
      font-size: 1.1rem;
    }
  }
}

.page-description {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.no-results {
  font-size: 0.9em;
  color: lighten($baseFontColor, 20%);
  margin: 0;
  padding: 1rem 0;
}

.boxed {
  background: $greyBlueMidDark;
  color: #fff;
  padding: 0.6rem 1rem;
  display: block;
  width: 100%;
  font-family: $fontPrimaryMedium !important;
  font-size: 1.2rem;
}
.back-button {
  font-family: $fontPrimaryBold;
  i {
    margin-right: 0.2rem;
  }
}

.text--alternative {
  color: $lighterFontColor;
}

.text--medium-small {
  font-size: 0.9rem;
}

.text--small {
  font-size: 0.85rem;
}
.text--default {
  font-size: 1rem !important;
}
.text--larger {
  font-size: 1.2rem !important;
}

.text--lg {
  font-size: 1.5rem;
}
.text--xl {
  font-size: 2rem;
}
.text--xxl {
  font-size: 3rem;
}

.text--line-through {
  text-decoration: line-through;
}

.text--regular {
  font-family: $fontPrimaryRegular !important;
}

/* Blockquote */
blockquote {
  color: $greyBlueMedium;
  font-size: 1.4rem;
  margin: 1.5rem 0;
  padding: 1rem 1rem;
  quotes: "\201C""\201D""\2018""\2019";
  font-family: $fontPrimaryLight;
  &:before {
    color: $greyBlueMedium;
    content: open-quote;
    font-size: 2em;
    line-height: 1rem;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  p {
    display: inline;
  }
}

@media screen and(min-width: $breakpoint-md) {
  html,
  body {
    font-size: $fontSizeBaseTabletPortrait;
  }
  h1 {
    font-size: 1.5rem;
    line-height: 1.3;
    font-family: $fontSecondaryBold;
  }
  h2 {
    font-size: 1.3rem;
    line-height: 1.4;
    font-family: $fontPrimaryRegular;
  }
}

/* Icon typography */
.icon--large {
  font-size: 3rem;
  margin: 1rem 0;
}
.icon--medium {
  font-size: 1.8rem;
  margin: 1rem 0;
}
