
@import "@/assets/scss/abstracts/_variables.scss";
.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: .9rem;
  line-height: 1.3;
  white-space: nowrap;
  > div {
    border-left: 1px solid rgba(255,255,255, .5);
    color: #fff;
    font-family: $fontPrimaryRegular;
    margin-left: .7rem;
    padding-left: .7rem;
    line-height: 1;
    padding-top: 0.32rem;
    padding-bottom: 0.2rem;
  }
  @media screen and (min-width: $breakpoint-md) {
    > div {
      margin-left: .75rem;
      padding-left: .75rem;
    }
  }
}
