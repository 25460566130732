
@import "@/assets/scss/abstracts/_variables.scss";
.contact-list {
  &__item {
    text-align: left;
    padding-bottom: 1.5rem;

    &__name,
    &__title,
    &__opening-hours,
    &__email,
    &__additional-info,
    &__address,
    &__tel {
      display: block;
      margin-top: 0.2rem;
    }

    a {
      border-bottom: 1px solid $baseFontColor;
      transition: all 0.2s ease-in-out;
      &:hover {
        border-color: $blueLightMedium;
      }
    }

    &__additional-info {
      font-size: 0.85rem;
      margin-top: .9rem;
    }
  }

  &__add-new-message {
    font-size: 0.9rem;
    font-family: $fontPrimaryMedium;
    margin-top: 1rem;
    a {
      border-bottom: 0px !important;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9em;

    &__item {
      flex-basis: 45%;
      max-width: 45%;
      width: 100%;
    }

    &__add-new-message {
      margin-top: 0;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding-top: 0;
    font-size: 0.9rem;

    .contact-list__item {
      margin-bottom: 1rem;
    }

    .contact-list__item__name {
      margin-top: 0;
    }
  }
}
