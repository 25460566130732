/* Slide down */
.slidedown-enter-active {
  transition: all 0.2s ease-in;
}
.slidedown-leave-active {
  transition: all 0.2s cubic-bezier(0, 1, 0.5, 1);
}
.slidedown-enter-to,
.slidedown-leave-from {
  max-height: 300px;
  overflow: hidden;
}
.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}

/* Slide Up */
.slideup-enter-active {
  transition: all 0.4s ease-in-out;
}
.slideup-leave-active {
  transition: all 0.4s ease-in-out;
}
.slideup-enter-to,
.slideup-leave-from {
  max-height: 340px;
  overflow: hidden;
}
.slideup-enter-from,
.slideup-leave-to {
  overflow: hidden;
  max-height: 0;
}

/* Slide right */
.slideright-enter-active {
  transition: transform 0.2s ease;
}

.slideright-leave-active {
  transition: transform 0.15s ease;
}

.slideright-enter-to,
.slideright-leave-from {
  transform: translateX(0);
  overflow: hidden;
}

.slideright-enter-from,
.slideright-leave-to {
  transform: translateX(-100%);
  @media screen and (min-width: $breakpoint-lg) {
    &.hide-from-desktop {
      transform: translateX(0px);
    }
  }
}

/* Slide right slow */
.sliderightslowly-enter-active {
  transition: transform 0.3s ease-out;
}

.sliderightslowly-leave-active {
  transition: transform 0.3s ease-out;
}

.sliderightslowly-enter-to,
.sliderightslowly-leave-from {
  transform: translateX(0);
  overflow: hidden;
}

.sliderightslowly-enter-from,
.sliderightslowly-leave-to {
  transform: translateX(-100%);
  @media screen and (min-width: $breakpoint-lg) {
  }
}

/* Slide left */
.slideleft-enter-active {
  transition: transform 0.2s ease;
}

.slideleft-leave-active {
  transition: transform 0.15s ease;
}

.slideleft-enter-to,
.slideleft-leave-from {
  transform: translateX(0);
  overflow: hidden;
}

.slideleft-enter-from,
.slideleft-leave-to {
  transform: translateX(100%);
  @media screen and (min-width: $breakpoint-lg) {
    &.hide-from-desktop {
      transform: translateX(0px);
    }
  }
}

/* Slide left slow */
.slideleftslowly-enter-active {
  transition: transform 0.3s ease-out;
}

.slideleftslowly-leave-active {
  transition: transform 0.3s ease-out;
}

.slideleftslowly-enter-to,
.slideleftslowly-leave-from {
  transform: translateX(0);
  overflow: hidden;
}

.slideleftslowly-enter-from,
.slideleftslowly-leave-to {
  transform: translateX(100%);
  @media screen and (min-width: $breakpoint-lg) {
  }
}

/* Fade in */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Fade in slowly */
.fadeslowly-enter-active,
.fadeslowly-leave-active {
  transition: opacity 0.5s ease;
}
.fadeslowly-enter-from, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Fade in */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Fade fast */
.fadefast-enter-active,
.fadefast-leave-active {
  transition: opacity 0.15s ease;
}
.fadefast-enter-from, .fadefast-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.shadow-on-hover {
  transition: box-shadow .15s ease-in;
  @media screen and (min-width: $breakpoint-lg) {
    &:hover {
      box-shadow: 0 .6em .8em -.5em $lighterFontColor,
        .4em .4em .9em .4em rgba(0, 0, 0, 0);
    }
  }
}

/* Zoom enter */
.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}