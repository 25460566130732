
@import "~@/assets/scss/abstracts/variables";
.page-section {
  &--light {
    .page-section__body {
      background: $backgroundColorGreyMid;
    }
  }
  &--outline {
    .page-section__body {
      border: 1px solid $borderColorDefault;
    }
  }
}
