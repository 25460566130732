
@import "@/assets/scss/abstracts/_variables.scss";
.feedback {
  .feedback__select {
    list-style: none;
    font-size: 1.8rem;
    padding: 1rem 0 0 0;
    li {
      display: inline-block;
      margin-right: 1rem;
      a {
        i {
          transition: transform 0.15s ease-in-out;
        }
        i:hover,
        i:focus {
          transform: scale(1.1);
        }
      }

      &.active {
        a {
          color: $blueMedium;
          i {
            transform: scale(1.3);
          }
        }
      }
    }
  }
  .input-group {
    textarea,
    label {
      display: block;
    }
    textarea {
      min-height: 100px;
      width: 100%;
    }
  }

  /*button {
    .loader-icon {
      margin: 0;
      top: 0.3rem;
      margin-right: 0.5rem;
    }
  }*/
}
