.news-list--latest {
	a {
		&.content-pick {
			&.content-pick--box {
				min-height: 154px;
				.content-pick__footer {
					display: none; // Remark: to make it specific to news/atp/yit only
				}
			}
		}
	}
	.content-pick__description {
		display: none; // Remark: to make it specific to news/atp/yit only
	}
}
