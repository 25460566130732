$fontSizeBaseMobile: 14px;
$fontSizeBaseTabletPortrait: 16px;

$fontPrimaryRegular: "Helvetica Now Micro W04 Regular", helvetica, arial;
$fontPrimaryBold: "Helvetica Now Micro W04 Bold", helvetica, arial;
$fontPrimaryMedium: "Helvetica Now Micro W04 Medium", helvetica, arial;
$fontPrimaryLight: "Helvetica Now Micro W04 Light", helvetica, arial;

$fontSecondaryRegular: "Helvetica Now Text W04 Regular", helvetica, arial;
$fontSecondaryBold: "Helvetica Now Text W04 Bold", helvetica, arial;

$fontIcon: "Font Awesome 5 Pro";

$orange: #e98300;
$orangeLight: #fff7ee;
$yellow: #e9be00;
$red: #db4d69;
$rose: #E894A4;
$rose50: #FBEDEF;
$rose40: #FCF1F3;
$rose30: #FDF4F6;
$blueMedium: #009fda;
$blueMediumLighter: #80c6ea;
$blueLightest: #e7edef;
$blueLight: #ccecf7;
$blueLightMedium: #b9c9dd;
$greyBlueDark: #1f3640;
$greyBlueMidDark: #25414D;
$greyBlueMedium: #546c76;
$greyBlueMidLight: #77919E;
$greyBlueLight: #BBC8CE;
$chateauGrey: #CED9E0;
$chateauGreyMidLight: #E6ECEF;
$chateauGreyLight: #F3F5F7;
$greyLight: $chateauGreyLight;
$greyLighter: #f9f9f9;
$backgroundGrey: $chateauGreyLight;
$white: #fff;
$green: #3f9c36;
$greenLight: #e7f4e6;

$baseFontColor: $greyBlueDark;
$lighterFontColor: #8596a3;
$fontColorLight: rgba(40, 71, 84, 0.67);
$brandColorPrimary: $greyBlueMidDark;
$brandColorPrimaryLight: lighten($greyBlueMidDark, 70%);
$brandColorSecondary: $blueLight;

$backgroundColorGreyLight: $chateauGreyLight;
$backgroundColorGreyMid: $backgroundGrey;
$backgroundPrimaryLight: #e7edef;

$backgroundHoverColor: $blueLightest;
$backgroundHoverColorLight: lighten($chateauGreyLight, 3%);

//$borderColorDefault: #bdbdbd;
$borderColorDefault: $greyBlueLight;
$borderColorLight: $chateauGrey;
$borderColorLightest: #e5e5e5;
$borderColorDark: $greyBlueMidLight;
$borderHoverColor: $blueLightMedium;

//Viewport breakpoints
$breakpoint-s: 425px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1500px;
$breakpoint-xxxl: 2000px;

$breakpoint-s-down: 524.98px;
$breakpoint-sm-down: 575.98px;
$breakpoint-md-down: 767.98px;
$breakpoint-lg-down: 991.98px;
$breakpoint-xl-down: 1199.98px;
