
.back-button {
  i {
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    i {
      transform: translateX(-0.4rem);
    }
  }
  border-bottom: none;
}
