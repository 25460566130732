
@import "@/assets/scss/abstracts/_variables.scss";
.add-quick-link {
  display: flex;
  flex-direction: column;
  background-color: $blueLightest;
  border: 1px solid $blueLight;
  margin-top: 1rem;
  .input-group {
    margin: .5rem 0 .5rem 0;
  }
  padding: .5rem 1rem;
  .buttons {
    margin-left: 1rem;
  }

  @media screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
    align-items: center;
    .input-group {
      max-width: 400px;
      margin: 0 0 0 .5rem;
    }
  }
}
