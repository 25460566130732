.status-circle {
  display: inline-block;
  position: relative;
  top: 0;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: $greyBlueMedium;
  margin-right: 0.1rem;

  &--closed,
  &--success {
    background-color: $green !important;
  }
  &--pending {
    background-color: $yellow !important;
  }
  &--open,
  &--error {
    background-color: $red !important;
  }
}
