.apartment-label {
  font-family: $fontPrimaryRegular;
  font-size: 0.7rem;
  display: inline-block;
  background: $blueLightest;
  padding: 0.1rem 0.3rem;
  vertical-align: top;
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
  text-transform: uppercase;
}

.comment {
  background-color: #fff;
  //padding: 1rem 1rem 0.5rem 1rem;
  padding: 1rem 0;
  border-top: 1px solid $borderColorDefault;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 1rem;

  &:first-child {
    margin-top: 0;
  }

  &__header {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    font-size: 0.9rem;
  }

  &__sender {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    .profile-icon {
      margin-right: 1rem;
    }
  }

  &__apartment {
    margin-left: 0.5rem;
    position: relative;
  }
  &__date {
    font-size: 0.8rem;
    font-family: $fontPrimaryLight;
    margin-top: 0;
    margin-left: auto;
    text-align: right;
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    margin-top: 1rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    a {
      border-bottom: 1px solid $greyBlueDark;
      padding: 0 0 0.1em 0;
      transition: border-color 0.15s ease-in;
      &:hover {
        border-bottom-color: $borderHoverColor;
      }
    }
  }
  &__footer {
    display: flex;
    margin-top: 1rem;
    button {
      font-size: 1rem;
    }
  }
  &__report-message {
    margin-left: auto;
  }
  &__display-messages {
    border: 0px;
    &:hover,
    &:focus,
    &:active {
      border: 0px;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    //padding: 1.2rem 1.2rem 0.7rem 1.2rem;
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;
  }

  /*&:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #fff;
    border-left: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-left: -1rem;
  }*/
  &--me {
    margin-left: 0;
    margin-right: 1rem;
    &:after {
      content: "";
      right: 0;
      left: auto;
      top: 50%;
      border: 20px solid transparent;
      border-left-color: #fff;
      border-right: 0;
      border-bottom: 0;
      margin-left: 0;
      margin-top: -10px;
      margin-right: -1rem;
    }
  }
  &--deleted {
    padding-bottom: 1.2rem;
    .comment__body {
      margin-top: 0;
    }
  }
}

.comment-sub-content {
  padding-left: 1rem;
  @media screen and (min-width: $breakpoint-md) {
    padding-left: 2rem;
  }
  @media screen and (min-width: $breakpoint-xl) {
    padding-left: 3rem;
  }
}