
@import "@/assets/scss/abstracts/_variables.scss";
.main-navigation {
  @media screen and (min-width: $breakpoint-lg) {
    padding: 0 0 2rem 0;
    position: sticky;
    top: 80px;
  }
  @media screen and (min-width: $breakpoint-xl) {
    margin-right: 1.5rem;
    max-width: 400px;
  }
  ul {
    background: $rose30;
    list-style: none;
    margin: 0 0 0.5rem;
    li {
      margin: 0;
      padding: 0;
      position: relative;
      &:first-child a {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    a {
      span {
        display: inline-block;
        width: 100%;
      }
      .link-icon {
        font-size: 1.2em;
        margin-right: 0.7em;
        position: relative;
        top: 1px;
      }
      display: flex;
      align-items: center;
      padding: 1.4em 1em 1.4em 2em;
      line-height: 1.3;
      font-size: 1.1em;
      text-decoration: none;
      border-bottom: 1px solid $greyBlueLight;
      transition: all 0.2s ease-in-out;

      &.router-link-exact-active,
      &.router-link-active,
      &.active,
      &:focus {
        background-color: $backgroundColorGreyMid;
        &:hover {
          background-color: $backgroundColorGreyMid;
        }
      }

      &:hover {
        background: $brandColorPrimaryLight;
        color: $baseFontColor;
      }
    }

    .navigation-toggle {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      border-bottom: 0px !important;
      font-size: 1.5rem;
      z-index: 11;
      padding: 1rem;
    }

    a.router-link-exact-active ~ .navigation-toggle,
    a.router-link-active ~ .navigation-toggle,
    .appended .navigation-toggle,
    &.active .navigation-toggle {
      i {
        transform: rotate(180deg);
      }
    }
    a.router-link-exact-active ~ .navigation-toggle,
    a.router-link-active ~ .navigation-toggle,
    &.active .navigation-toggle {
      color: #fff;
    }

    /** Level 2 */
    ul,
    ol {
      margin: 0;
      li {
        a {
          padding-left: 3rem;
          font-size: 1.1rem;

          &.router-link-exact-active,
          &.active,
          &:focus {
            background-color: $brandColorPrimaryLight;
            color: $baseFontColor;
            &:hover {
              background-color: $brandColorPrimaryLight;
              color: $baseFontColor;
            }
          }
        }
      }
    }
  }
  .section-switcher {
    a {
      color: $blueMedium;
      display: block;
      margin-bottom: .5rem;
      span {
        text-decoration: underline;
      }
    }
    padding-left: 1rem;
    padding-top: 0.8rem;
    i {
      padding: 0 0.5rem;
    }
    &__to-home,
    &__to-admin {
      i {
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
