
@import "@/assets/scss/abstracts/_variables.scss";
.announcements-count {
  width: 0.8rem;
  height: 0.8rem;
  padding: 1px;
  border-radius: 50%;
  background-color: $red;
  color: #fff;
  display: block;
  position: absolute;
  font-size: 0.6rem;
  top: 0.5rem;
  right: 0.1rem;
  /*border: 1px solid #fff;*/
  animation: scaleUp 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);

  @media screen and (min-width: $breakpoint-md) {
    width: 0.7rem;
    height: 0.7rem;
    top: 0.8rem;
    right: 0.25rem;
  }
}
.notification-prompt {
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 300px;
  z-index: 10;
  color: $baseFontColor;
  &.is-visible {
    box-shadow: 0px;
  }
  &.is-visible {
    display: block;
  }

  &:before {
    content: "";
    top: -8px;
    left: auto;
    right: 1rem;
    z-index: 0;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1rem 1rem;
    border-color: transparent transparent $greyBlueMidDark transparent;
    transition: all 0.2s ease-in-out 0.5s;
  }

  @media screen and (min-width: $breakpoint-lg) {
    max-width: 500px;
    right: -1rem;
    left: auto;
  }
}
