
@import "~@/assets/scss/abstracts/variables";
.content-pick {
  background-color: #fff;
  @media screen and (min-width: $breakpoint-md) {
    &__arrow-link {
      margin-right: 1rem !important;
    }
  }
}
